import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

 
const MerchantCouponsComponent = Loadable({
  loader: () => import("./components/merchant-coupon/coupons"),
  loading: Loading
});
const AddEditCouponComponent = Loadable({
  loader: () => import("./components/add-edit-coupon/addEditCoupon"),
  loading: Loading
});
const CouponStatistics = Loadable({
  loader: () => import("./components/coupon-statistics/couponStatistics"),
  loading: Loading
});
 
const CouponReviewComponent = Loadable({
  loader: () => import("../userCoupon/components/coupon-review/reviewDetails"),
  loading: Loading
});
 

export const routes = [
  
  {
    path: BASPATH + "coupons",
    exact: true,
    name: "coupons",
    element: MerchantCouponsComponent
  },
  {
    path: BASPATH + "addEditCoupon",
    exact: true,
    name: "AddEditCoupon",
    element: AddEditCouponComponent
  },
  {
    path: BASPATH + "couponStatistics",
    exact: true,
    name: "CouponStatistics",
    element: CouponStatistics
  },
  {
    path: BASPATH + "couponReview",
    exact: true,
    name: "MyCoupons",
    element: CouponReviewComponent
  },
   
  
];

export * from "./components";
