import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";


const Plans = Loadable({
    loader: () => import("./components/index"),
    loading: Loading
  });


  export const routesPlans = [
    {
      path: BASPATH + "plans",
      name: "plans",
      element: Plans
    },
    
  ];