import React, { useContext, useEffect, useState } from "react";
import { postLiveStream } from "../redux/actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { ShowErrorToast, ShowToast } from "../utils/common";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";

const UploadFileContext = React.createContext();

export function UseUploadContext() {
  return useContext(UploadFileContext);
}

export function UploadFileProvider({ children }) {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const authUser = useSelector(({ auth }) => auth.data);

  const [isUploading, setUploading] = useState(false);

  const handleUploadVideo = async (user_id, blobs, room, blobSize) => {
    if (blobs) {
      console.log('Uploading file...');

      console.log('blobSize', blobSize)
      const params = {
        user_id: user_id, // location.state.itemId,
        blob: blobs,
        room_id: room.id,
        blob_size: blobSize
      };
      const data = new FormData();
      Object.keys(params).forEach(key => {
        data.append(key, params[key]);
      });
      setUploading(true)
      console.log("postLiveStream data", data);
      dispatch(postLiveStream(data, authUser.token))
        .then(res => {
          setUploading(false)
          console.log('postLiveStream res', " aewq2323232323", res);
          if (res.status == 200) {
            setTimeout(() => {
              const options = {
                title: t("common:upload"),
                message: res.message,
                buttons: [
                  {
                    label: t("common:ok"),
                    onClick: () => {
                    }
                  },
                ],
                closeOnEscape: true,
                closeOnClickOutside: true,
                keyCodeForClose: [8, 32],
                willUnmount: () => { },
                afterClose: () => { },
                onClickOutside: () => { },
                onKeypress: () => { },
                onKeypressEscape: () => { },
                overlayClassName: "overlay-custom-class-name"
              };

              confirmAlert(options);
            }, 100);
          } else {
            ShowToast(res.message)
          }
        })
        .catch(error => {
          setUploading(false)
          console.error("postLiveStream Download error:", error);
          ShowErrorToast(error)
          ShowToast(error.message || 'Failed to download.')
        });
    }
  };

  // useEffect(() => {
  //   return () => {
  //   }
  // }, []);

  return (
    <UploadFileContext.Provider value={{
      isUploading,
      setUploading,
      handleUploadVideo
    }} >
      {children}
    </UploadFileContext.Provider>
  );
}


