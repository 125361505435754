import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

 
const TermsConditionComponent = Loadable({
  loader: () => import("./components/terms"),
  loading: Loading
});
 
export const routes = [
  {
    path: BASPATH + "terms",
    exact: true,
    name: "terms",
    element: TermsConditionComponent
  },
];

export * from "../auth/components";
