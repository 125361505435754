export default {
    home: 'مسكن',
    login: 'تسجيل الدخول',
    register: "يسجل",
    settings: 'إعدادات',
    signin: 'تسجيل الدخول',
    otpView: 'OtpView',
    myCoupons: 'القسائم الخاصة بي',
    addCoupon: 'أضف القسيمة',
    editCoupon: 'تحرير القسيمة',
    myCouponsClient: 'القسائم الخاصة بي',
    backCouponsList: 'العودة إلى قائمة القسائم',
    backPublicFeed: 'العودة إلى مجموعات التوراة',
    couponStatistics: 'إحصائيات الكوبونات',
    backBlogs: 'العودة إلى المدونات',
    myProfile:"ملفي",
    myProfileMerchant:"الملف الشخصي",
    smartCitizenDebitCard: "بطاقة ائتمان سمارت سيتيزن",
    aboutSmartCitizenApp:"حول تطبيق المواطن الذكي",
    notification:"اشعارات",
    help :"مساعدة",
    termsncondition:"البنود و الظروف",
    logout:"تسجيل خروج",
    forgotPassword: 'هل نسيت كلمة السر',
    resetPassword: 'إعادة تعيين كلمة المرور',
    generateOtp: 'لانشاء رمز التحقق',
    couponReview: 'المراجعات',
    messages:"بريد الكتروني",
    accountPrivacy:"الخصوصية حساب",
    settingsPrivacy:"الإعدادات والخصوصية",
    postDetail:"تفاصيل الخلاصة العامة",
    socialHome:"تغذية عامة",
    postCreate:"إنشاء مشاركة",
    postProfile:"الملف الاجتماعي",
    chat:"محادثة",
    blockedUsers:"مستخدمين محجوبين",
    connectedUsers:"المستخدمون المتصلون",
    likes:"الإعجابات",






 //web app text
    guestuser: 'حساب زائر',
    social: "اجتماعي",
    plans: "Plans",
  };