import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { BASPATH } from "../../config/appConfig";
import { registerApi } from "../../redux/actions/loginActions";
import MerchantForm from "./MerchantForm";

// Component CSS
import classes from "./SignUpForm.module.css";
import UserForm from "./UserForm";
import { ShowErrorToast, ShowToast } from "../../utils/common";
import LoadingSpinner from "../Layout/LoadingSpinner";
import {
  fetchToken,
  requestPermission
} from "../../firebaseNotifications/firebase";

const SignUpForm = () => {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();

  const [isUser, setisUser] = useState(true);

  const [loading, setLoading] = useState(false);
  const [errorState, setErrors] = useState({});

  const [deviceToken, setDeviceToken] = useState("");

  const dispatch = useDispatch();

  const [loginState, setLoginState] = useState({
    device_type: "WebApp",
    user_type: "user"
  });

  const [userState, setUserState] = useState({
    user_status: 0,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone_number: "",
    id_number: "",
    marital_status: "",
    no_of_child: "",
    occupation: "",
    education_level: "",
    street_address_name: "",
    street_number: "",
    house_number: "",
    city: "",
    district: ""
  });

  const [merchantState, setmerchantState] = useState({
    user_status: 1,
    business_name: "",
    registration_number: "",
    email: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
    website: "",
    business_activity: "",
    business_sector: "",
    establishment_year: "",
    business_logo: "",
    from: "",
    to: "",
    fromAMPM: "AM",
    toAMPM: "PM",
    marital_status: "",
    street_address_name: "",
    street_number: "",
    district: ""
  });

  console.log("SignInForm deviceToken", deviceToken);
  useEffect(() => {
    requestPermission().then(res => {
      if (res) {
        fetchToken().then(res => {
          setDeviceToken(res);
        });
      }
    });
    return () => {};
  }, [i18n.language]);

  const handleInputChange = e => {
    //  e.preventDefault()
    const { name, value } = e.target;
    console.log("name, value", name, value);
    setLoginState(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === "user_type") {
      setisUser(value === "user");
    }
  };
  // console.log('loginState', loginState)
  const handleInputChangeMerchant = e => {
    const { name, value } = e.target;
    console.log("name, value", name, value);
    setmerchantState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleInputChangeUser = e => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("name, value", name, value);
    setUserState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onRegisterApiCall = params => {
    setLoading(true);
    dispatch(registerApi(params))
      .then(response => {
        setLoading(false);
        if (response.status == 200) {
          navigate(BASPATH + "auth/otp-view");
        }
        ShowToast(response.message);
      })
      .catch(error => {
        setLoading(false);
        ShowErrorToast(error);
      });
  };

  const handleUserRegister = () => {
    let user = {
      ...userState,
      device_type: "WebApp",
      user_status: loginState.user_type === "user" ? 0 : 1,
      device_token: deviceToken,
      latitude: 0,
      longitude: 0,
      language_code: i18n.language
    };
    console.log("handleUserRegister >>>", user);
    let fields = user;
    onRegisterApiCall(fields);
  };
  const handleMerchantRegister = () => {
    let user = {
      ...merchantState,
      device_type: "WebApp",
      user_status: loginState.user_type === "user" ? 0 : 1,
      device_token: deviceToken,
      latitude: 0,
      longitude: 0,
      //   latitude: location.coords ? location.coords.latitude : 0,
      //   longitude: location.coords ? location.coords.longitude : 0,
      language_code: i18n.language
    };
    console.log("handleMerchantRegister >>>", user);
    let fields = user;
    onRegisterApiCall(fields);
  };

  return (
    <div className="signin-content-right" style={{ padding: "80px 80px" }}>
      <h2 className="h2 fw-bold">{t("navigate:register")}</h2>
      <Form>
        <Row className="mb-3 ">
          <Col className="col-4">
            <span className={classes.signinastext}>
              {t("common:registerAs")}
            </span>
          </Col>

          <Col className="col-4 d-flex align-items-center">
            <input
              className="discounttype"
              type="radio"
              name="user_type"
              value={"merchant"}
              checked={loginState.user_type === "merchant"}
              onChange={handleInputChange}
            />
            <span className="discounttype ms-1 me-1">
              {t("common:merchant")}
            </span>
          </Col>
          <Col className="col-4 d-flex align-items-center">
            <input
              type="radio"
              name="user_type"
              value={"user"}
              checked={loginState.user_type === "user"}
              onChange={handleInputChange}
            />
            <span className="discounttype ms-1 me-1">{t("common:user")}</span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="col-12">
            {isUser ? (
              <span className={`${classes.signinastext} textblue`}>
                {t("common:personalInformation")}
              </span>
            ) : (
              <span className={`${classes.signinastext} textblue`}>
                {t("common:businessInformation")}
              </span>
            )}
          </Col>
        </Row>

        {isUser ? (
          <UserForm
            userState={userState}
            errorState={errorState}
            handleInputChangeUser={handleInputChangeUser}
            handleRegister={handleUserRegister}
          />
        ) : (
          <MerchantForm
            userState={merchantState}
            errorState={errorState}
            handleInputChangeUser={handleInputChangeMerchant}
            handleRegister={handleMerchantRegister}
          />
        )}
      </Form>

      <div className="loader-wrapper mt-2">
        <LoadingSpinner loading={loading} />
      </div>
    </div>
  );
};

export default SignUpForm;
