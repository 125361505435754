// import { SOCKET_URL_ENDPOINT } from "../../config/api/index.js";
import React from "react";
import io from "socket.io-client";
import { IS_CLIENT_SERVER, IS_DEBUG } from "../config/appConfig.js";
import { useSelector } from "react-redux";
const SocketContext = React.createContext();
const SocketProvider = ({ children }) => {
  const authUser = useSelector(({ auth }) => auth.data);
  //console.log('SocketProvider socket')
  // const URL = IS_CLIENT_SERVER ? "http://78.47.88.161:5001" : 'http://15.207.152.121:5001';
  const URL = IS_CLIENT_SERVER
    ? "https://api.toshavhaham.co.il/"
    : "http://15.207.152.121:5001";
  // console.log("authUser => ", authUser);
  var socket = io(URL, {
    autoConnect: false,
    cors: {
      origin: "http://localhost:3000"
    },
    transports: ["polling"],
    protocols: ["https"],
    pingTimeout: 60000
  });
  // console.log('SocketProvider socket', socket)
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };

// Headers kya ch
