import React from "react";
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

// Component CSS
import classes from './SignIn.css';

// API Config
import api from "../configs/api";

// Card Component
import AuthCard from "../components/UI/AuthCard";
import AuthCardLeft from "../components/UI/AuthCardLeft";
import AuthCardRight from "../components/UI/AuthCardRight";
import SignInForm from "../components/Auth/SignInForm";
import { useTranslation } from "react-i18next";
import "./SignIn.css"



const SignIn = ({setSignUp}) => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const navigateHandler = () => {
     // navigate('signup')
  }
  return (
    <>
      <AuthCard>
        <Row>
          <Col className="blue">
            <AuthCardLeft className={"signincontent blue"}>
              <div className="signin-content-left" style={{padding : '80px 80px'}}>
                <h2 className='signin-font-white h2 fw-bold '>{t('common:donHaveAccount')}</h2>
                {/* <p className={classes.signinp}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                <Button onClick={()=> setSignUp(true)} variant="outline-primary" className='site-btn white-bg rounded-btn mt-3'>{t('navigate:register')}</Button>
              </div>
            </AuthCardLeft>
          </Col>
          <Col>
            <AuthCardRight className={classes.signincontent}>
              <SignInForm   />
            </AuthCardRight>
          </Col>
        </Row>
      </AuthCard>
    </>
  );
};

export default SignIn;

export async function action({request}) {
  const data = request.formData();
  const authData = {
    emai : data.get('email'),
    password : data.get('password')
  }

  const response = await fetch(api.base_url_api + api.loginEndpoint,{
    method : 'POST',
    headers : {
       'Content-Type' : 'application/json'
    },
    body : JSON.stringify(authData)
  });

  console.log(response);

}