import React from 'react'
import Helmet from "react-helmet";

const HeadTags = ({
    title = "",
    metaDescription = "",
    image,
    video
}) => {
    console.log('HeadTags image', image)
    console.log('HeadTags video', video)
    return (
        <Helmet>
            <title>{title}</title>
            {/* <head prefix="my_namespace: https://example.com/ns#"> */}
            {/* <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="description" key="description" content={metaDescription} />
            <meta name="title" key="title" content={title} />
            <meta property="og:title" key="og:title" content={title} />
            <meta property="og:locale" key="og:locale" content="en_US" />
            <meta charSet="utf-8" />
            <meta property="og:url" content={video} data-react-helmet="true" />
            <meta property="og:type" key="og:type" content="website" />
            <meta
                property="og:description"
                key="og:description"
                content={metaDescription}
            /> */}

            {/* {video ? <meta
                property="og:video"
                key="og:video"
                content={video}
            /> : null} */}

            {/* <link rel="icon" href="https://toshavhaham.co.il/static/media/logo.02a0873f87f33dc16d82.png" /> */}

            {/* <meta property="og:image" content={'https://toshavhaham.co.il/static/media/logo.a2cac8ac3f8f1daf6085.png'} /> */}
            {/* {image ? <meta property="og:image" content={'https://toshavhaham.co.il/smartapp/public/uploads/blog/1682447614logo-01 ABCD.png'} /> : null} */}

        </Helmet>
    );
};

export default HeadTags