export default {
  first_name: 'נא להזין שם פרטי.',
  last_name: 'נא להזין שם משפחה.',
  email: 'נא להזין כתובת אימייל חוקית.',
  password: 'נא להזין סיסמה חוקית.',
  current_Password: 'אנא הזן סיסמה נוכחית חוקית.',
  new_Password: 'אנא הזן סיסמה חדשה חוקית.',
  password_confirmation_new: 'הסיסמה הנוכחית והסיסמה החדשה אינם תואמים.',
  password_confirmation: 'סיסמה ואישור הסיסמה אינם תואמים.',
  phone_number: 'נא להזין מספר טלפון חוקי.',
  id_number: 'נא להזין מספר תעודת זהות.',
  marital_status: 'אנא בחר מצב משפחתי.',
  no_of_child: 'נא לבחור מספר ילדים.',
  occupation: 'נא להיכנס לעיסוק.',
  education_level: 'אנא בחר רמת השכלה.',
  house_number: 'נא להזין מספר בית',
  city: 'נא להיכנס לעיר.',
  coupon_title: 'נא להזין את כותרת הקופון.',
  business_name: 'נא להזין את שם העסק.',
  registration_number: 'נא להזין מספר רישום.',
  website:'נא להיכנס לאתר.',
  location_url:'אנא הזן את כתובת האתר של המיקום.',
  business_activity:'אנא בחר בפעילות עסקית.',
  businessSector:'נא לבחור במגזר העסקי.',
  establishment_year:'נא להזין שנת הקמה תקפה.',
  business_hours:'נא להזין את שעות הפעילות.',
  street_address_name:'נא להזין את שם כתובת הרחוב.',
  street_number:'נא להזין מספר רחוב.',
  district:'נא להיכנס למחוז שלך.',
  code:'אנא הזן את הקוד שלך.',
  oneTimePassword:'נא להזין סיסמה חד פעמית (OTP).',
  terms:'אנא הוסף תנאים והגבלות',

  coupon_description: "נא להזין את תיאור הקופון",
  coupon_code: "נא להזין קוד קופון",
  qrcode_url: "נא להזין כתובת URL של קוד QR",
  discount_amount: "נא להזין סכום הנחה",
  discount_type: "אנא בחר סוג הנחה",
  discount_percent: "הנחה מקסימלית צריכה להיות 100%.",
  location_id: "אנא בחר מיקום",
  category_id: "אנא בחר קטגוריה",
  delete_coupon: "האם אתה בטוח שברצונך למחוק את הקופון הזה?",
  
  blogs_not_found:'בלוגים לא נמצאו',
  coupons_not_found:'קופונים לא נמצאו',
  feed_not_found:'אוסף תורה לא נמצא',
  notification_not_found:'הודעות לא נמצאו',
  recent_comment_not_found:'התגובה האחרונה לא נמצאה',
  optional:'אופציונאלי',
  review_not_found:'ביקורות לא נמצאו',
  review:'ביקורות לא נמצאו',
  no_chat_rooms:'לא נוצרו חדרים!',
  user_type:'אנא בחר סוג משתמש',
  users_not_found:'משתמשים לא נמצאו',
  publicFeed_not_found:'עדכון ציבורי לא נמצא',
  gender_error: 'אנא בחר מגדר.',

  
};