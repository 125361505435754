import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { adminRoutes, authRoutes, merchantRoutes, userRoutes } from "./routes";
import MainConent from "./components/Layout/MainContent";
//import Sidbar from "./components/Layout/SideMenu";
import HeaderView from "./components/Layout/Header";
import { Auth } from "./views/auth/components";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
//import { authUser } from "./common/utils/auth-header";
//import { config } from "./config/portal-config";
//import { GET_USER_BY_TOKEN_SUCCESS, LOG_OUT } from "./store/auth/actionTypes";
import { BASPATH, IS_CLIENT_SERVER } from "./config/appConfig";
import { Outlet, useLocation } from "react-router";
import { actions } from "./redux/actions";
import HeaderMain from "./components/Layout/HeaderMain";
import Footer from "./components/Layout/Footer";
import { useTranslation } from "react-i18next";
import localStorage from "redux-persist/es/storage";
import NotFound from "./components/Layout/NotFound";
import { allowedDomains, currentDomain } from "./App";
import { routesSocialChild } from "./views/social";
import { routesLiveStreamBroadcaster } from "./views/liveStream";

//const currentDomain = window.location.hostname;

//const allowedDomains = IS_CLIENT_SERVER? ["http://toshavhaham.co.il/"]:  ["", "http://15.207.152.121/"];

const AllRoutes = props => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  let location = useLocation();
  const { t, i18n } = useTranslation();

  console.log("AllRoutes location", location.pathname === "/");

  console.log("i18n", i18n.language);

  const auth = useSelector(state => state.auth);

  const user = auth.data;

  console.log("currentDomain", currentDomain);

  const routes = user
    ? user.user_status === 1
      ? merchantRoutes
      : user.user_status === 0
        ? userRoutes
        : userRoutes
    : userRoutes;

  //  const routes = userRoutes

  useEffect(() => {
    console.log("aroutes", routes);
    if (auth.loggedIn) {
      // let user = authUser();
      //  console.log("user AllRoutes ", user);
      //   console.log("user AllRoutes >>>>", location.pathname, user);
      //  console.log('user.userId === ""', user.userId === "")
      // console.log('user.userId === undefined || user.userId === ""', user.userId === undefined || user.userId === "")
      if (user.userId === undefined || user.userId === "") {
        //   console.log('BASPATH == location.pathname', BASPATH, location.pathname)
        // localStorage.removeItem("authUser");
        //localStorage.removeItem(config.authTokenKey);
        // dispatch({
        //   type: actions.login.LOGOUT,
        // });

        //  navigate(BASPATH + "auth/login");
        // navigate(BASPATH);
      } else {
        //console.log('GET_USER_BY_TOKEN_SUCCESS', user && user.id)
        dispatch({
          type: actions.login.GET_USER_BY_TOKEN_SUCCESS,
          payload: user
        });
      }
    } else {
      // if (location.pathname === "/")
      //   navigate(BASPATH + "auth/login");
    }
  }, [auth.loggedIn]);

  return (
    <Routes>
      {allowedDomains.includes(currentDomain) &&
        authRoutes.map((route, idx) => {
          //   console.log('route', route)
          return route.element ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={<route.element />}
            />
          ) : (
            <Navigate to={BASPATH} /> //+ "auth/login"
          );
        })}
      {allowedDomains.includes(currentDomain) ? (
        routes.map((route, idx) => {
          // console.log("routes route >>>>", idx);
          return route.path === "/social/*" && route.element ? (
            <Route
              key={idx}
              //index={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={
                // <Auth>
                <div>
                  <HeaderMain />
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      background: "#f8f8f8",
                      paddingBottom: 30
                    }}
                  >
                    {/* <Sidbar /> */}
                    <div style={{ width: "100%" }}>
                      <MainConent>
                        <route.element />
                      </MainConent>
                    </div>
                  </div>
                  <Footer />
                </div>
                // </Auth>
              }
            >
              {routesSocialChild.map((route, idx) => {
                // console.log("routesSocialChild.path", route.path);
                return route.element ? (
                  <Route
                    key={idx}
                    index={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                ) : (
                  <Navigate to={BASPATH} /> //+ "auth/login"
                );
              })}
            </Route>
          ) : route.element ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={
                // <Auth>
                <div>
                  <HeaderMain />
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      background: "#f8f8f8",
                      paddingBottom: 30
                    }}
                  >
                    {/* <Sidbar /> */}
                    <div style={{ width: "100%" }}>
                      <MainConent>
                        <route.element />
                      </MainConent>
                    </div>
                  </div>
                  <Footer />
                  <Outlet />
                </div>
                // </Auth>
              }
            />
          ) : (
            <Navigate to={BASPATH} /> //+ "auth/login"
          );
        })
      ) : (
        <>
          <Navigate to={BASPATH} />
        </>
      )}
      {allowedDomains.includes(currentDomain) &&
        routesLiveStreamBroadcaster.map((route, idx) => {
          //   console.log('route', route)
          return route.element ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={<route.element />}
            />
          ) : (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={<NotFound />}
            />
          );
        })}

      <Route path="*" >
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
export default AllRoutes;
