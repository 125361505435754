// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import firebase from "firebase/app";

import {
  getMessaging,
  getToken,
  onMessage,
  Messaging
} from "firebase/messaging";
import { GOOGLE_VAPID_KEY } from "../config/appConfig";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmtefy3-P0R8_wM1QH51tQJX2Z6o6jo-w",
  authDomain: "smart-citizen-df2b4.firebaseapp.com",
  projectId: "smart-citizen-df2b4",
  storageBucket: "smart-citizen-df2b4.appspot.com",
  messagingSenderId: "874633988498",
  appId: "1:874633988498:web:bd7c15716d54f7a58d194b",
  measurementId: "G-1XX7BR66F5"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const requestPermission = async () => {
  console.log("Requesting User Permission......");
  return Notification.requestPermission().then(permission => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return true;
    } else {
      console.log("User Permission Denied.");
      return false;
    }
  });
};

// requestPermission();

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then(firebaseToken => {
        console.log("requestPermission firebaseToken ", firebaseToken);
        resolve(firebaseToken);
      })
      .catch(err => {
        reject(err);
      });
  });

export const requestForToken = async () => {
  return getToken(messaging, { vapidKey: GOOGLE_VAPID_KEY })
    .then(currentToken => {
      if (currentToken) {
        // console.log("requestForToken current token for client: ", currentToken);
        // Perform any other neccessary action with the token
        return currentToken;
      } else {
        requestPermission();
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch(err => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
      console.log("onMessage payload", payload);
    });
  });

export const fetchToken = () =>
  new Promise((resolve, reject) => {
    const currentToken = getToken(messaging, { vapidKey: GOOGLE_VAPID_KEY });
    if (currentToken) {
      // console.log("current token for client: ", currentToken);
      resolve(currentToken);
    } else {
      console.log("An error occurred while retrieving token. ");
      reject("");
    }
  });
