export default {
  first_name: 'Пожалуйста, введите имя.',
  last_name: 'Пожалуйста, введите фамилию.',
  email: 'Пожалуйста, введите действительный адрес электронной почты..',
  password: 'Пожалуйста, введите правильный пароль.',
  current_Password: 'Пожалуйста, введите действительный текущий пароль.',
  new_Password: 'Пожалуйста, введите действительный новый пароль.',
  password_confirmation_new: 'Текущий пароль и новый пароль не совпадают.',
  password_confirmation: 'Пароль и подтверждение пароля не совпадают.',
  phone_number: 'Пожалуйста, введите действующий телефонный номер.',
  id_number: 'Пожалуйста, введите идентификационный номер.',
  marital_status: 'Пожалуйста, выберите семейное положение.',
  no_of_child: 'Пожалуйста, выберите количество детей.',
  occupation: 'Пожалуйста, выберите профессию.',
  education_level: 'Пожалуйста, выберите уровень образования.',
  house_number: 'Пожалуйста, введите номер дома',
  city: 'Пожалуйста, укажите город.',
  coupon_title: 'Пожалуйста, введите название купона.',
  business_name: 'Пожалуйста, введите название компании.',
  registration_number: 'Пожалуйста, введите регистрационный номер.',
  website:'Введите URL-адрес.',
  location_url:'Введите URL-адрес местоположения.',
  business_activity:'Пожалуйста, выберите вид деятельности.',
  businessSector:'Пожалуйста, выберите сферу деятельности.',
  establishment_year:'Пожалуйста, введите действительный год основания.',
  business_hours:'Пожалуйста, укажите часы работы.',
  street_address_name:'Пожалуйста, введите название улицы.',
  street_number:'Пожалуйста, введите номер улицы.',
  district:'Пожалуйста, укажите регион вашей страны.',
  code:'Пожалуйста, введите свой код.',
  oneTimePassword:'Пожалуйста, введите одноразовый пароль (OTP).',
  terms:'Пожалуйста, добавьте условия',
  
  coupon_description: "Пожалуйста, введите описание купона",
  coupon_code: "Пожалуйста, введите код купона",
  qrcode_url: "Пожалуйста, введите URL-адрес QR-кода",
  discount_amount: "Пожалуйста, введите сумму скидки",
  discount_type: "Пожалуйста, выберите тип скидки",
  discount_percent: "Максимальная скидка должна составлять 100%.",
  location_id: "Пожалуйста, выберите место",
  category_id: "Пожалуйста, выберите категорию",
  delete_coupon: "Вы уверены, что хотите удалить этот купон?",
  
  blogs_not_found:'Блоги не найдены',
  coupons_not_found:'Купоны не найдены',
  feed_not_found:'Сборник Торы не найден.',
  notification_not_found:'Сообщений не найдено',
  recent_comment_not_found:'Последний комментарий не найден',
  optional:'Необязательный',
  review_not_found:'Обзоров не найдено',
  review:'Обзоров не найдено',
  no_chat_rooms:'Сообщений не найдено!',
  user_type:'Пожалуйста, выберите тип пользователя',
  users_not_found:'Пользователи не найдены',
  publicFeed_not_found:'Публичный канал не найден',
  gender_error: 'Пожалуйста, выберите Пол.',
};