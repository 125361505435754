import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { BASPATH } from "../../config/appConfig";
import { loginApi } from "../../redux/actions/loginActions";
import { ShowErrorToast, ShowToast } from "../../utils/common";

// Component CSS
import classes from "./SignInForm.module.css";
import LoadingSpinner from "../Layout/LoadingSpinner";
import SocialLogin from "./SocialLogin";
import {
  fetchToken,
  requestPermission
} from "../../firebaseNotifications/firebase";

//import AppleSignIn from 'apple-signin-auth';

const SignInForm = ({ setSignUp }) => {
  const { t, i18n } = useTranslation();

  const [deviceToken, setDeviceToken] = useState("");

  let navigate = useNavigate();
  const form = useRef();
  const [isUser, setisUser] = useState(true);

  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
    device_type: "WebApp",

    user_type: "user"
  });
  const [loading, setLoading] = useState(false);

  const [errorState, setErrors] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    requestPermission().then(res => {
      console.log("res", res);
      if (res) {
        fetchToken().then(res => {
          setDeviceToken(res);
        });
      }
    });
    return () => {};
  }, []);

  console.log("SignInForm deviceToken", deviceToken);

  const handleInputChange = e => {
    const { name, value } = e.target;
    // console.log('name, value', name, value)
    setLoginState(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === "user_type") {
      setisUser(value === "user");
    }
  };

  const validateForm = () => {
    let fields = loginState;
    let errors = {};
    let formIsValid = true;

    // console.log('loginState', loginState)

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = t("error:email");
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = t("error:password");
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleLogin = e => {
    e.preventDefault();
    let user = {
      ...loginState,
      user_status: loginState.user_type === "user" ? 0 : 1,
      device_token: deviceToken,
      latitude: 0,
      longitude: 0,
      //   latitude: location.coords ? location.coords.latitude : 0,
      //   longitude: location.coords ? location.coords.longitude : 0,
      //   email: loginState.email,
      //   password: loginState.password,
      language_code: i18n.language
    };
    //  console.log('Pressed', user);

    if (validateForm()) {
      let fields = loginState;
      onLogin(user);
      // setLoading(true);
      // dispatch(login(loginState))
      //   .then((res) => {
      //     setLoading(false);
      //     navigate(BASPATH);
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //     console.error(error);
      //   });
      // }
    }
  };

  // const handleAppleSignIn = async () => {
  //     console.log("handleAppleSignIn")
  //     try {
  //       const response = await AppleSignIn.init({
  //         clientId: 'com.smartcitizen',
  //         redirectURI: 'http://localhost:3000/auth/login', // Replace with your actual redirect URI
  //         scope: 'email name',
  //         state: 'some-state',
  //       });

  //       // Handle the successful response (get tokens, user info, etc.)
  //       console.log('Apple Sign In Response:', response);
  //     } catch (error) {
  //         console.log('Apple Sign In error:', error);
  //     //   if (error instanceof AppleSignInErrorResponse) {
  //     //     // Handle error response from Apple Sign In
  //     //     console.error('Apple Sign In Error:', error);
  //     //   } else {
  //     //     // Handle other errors
  //     //     console.error('An error occurred:', error);
  //     //   }
  //     }
  //   };
  // const onAppleLogin = () => {
  //     const options = {
  //         clientID: 'com.smartcitizen', // Apple Client ID
  //         redirectUri: 'https://toshavhaham.co.il/auth/login',
  //         // OPTIONAL
  //         state: 'state', // optional, An unguessable random string. It is primarily used to protect against CSRF attacks.
  //         responseMode: 'query' | 'fragment' | 'form_post', // Force set to form_post if scope includes 'email'
  //         scope: 'name email' // optional
  //       };

  //       const authorizationUrl = AppleSignIn.getAuthorizationUrl(options);
  //       console.log('authorizationUrl', authorizationUrl)
  //     //  getAccessToken(authorizationUrl)

  // }

  // const getAccessToken = async(code) =>{
  //     const clientSecret = AppleSignIn.getClientSecret({
  //         clientID: 'com.company.app', // Apple Client ID
  //         teamID: 'teamID', // Apple Developer Team ID.
  //         privateKey: 'PRIVATE_KEY_STRING', // private key associated with your client ID. -- Or provide a `privateKeyPath` property instead.
  //         keyIdentifier: 'XXX', // identifier of the private key.
  //         // OPTIONAL
  //         expAfter: 15777000, // Unix time in seconds after which to expire the clientSecret JWT. Default is now+5 minutes.
  //       });

  //       const options = {
  //         clientID: 'com.company.app', // Apple Client ID
  //         redirectUri: 'http://localhost:3000/auth/apple/callback', // use the same value which you passed to authorisation URL.
  //         clientSecret: clientSecret
  //       };

  //       try {
  //         const tokenResponse = await AppleSignIn.getAuthorizationToken(code, options);
  //       } catch (err) {
  //         console.error(err);
  //       }
  // }

  const onLogin = user => {
    console.log("Pressed", user);
    setLoading(true);
    dispatch(loginApi(user))
      .then(response => {
        setLoading(false);
        console.log("response", response);
        if (response.status == 200) {
          if (response.data.is_verified_mobile_no === 0)
            navigate(BASPATH + "auth/otp-view");
          else navigate(BASPATH);
          //navigate(BASPATH);
        } else {
          console.log("login res error:::", response);
        }
        ShowToast(response.message);
        // Toast.show(response.message, Toast.SHORT);
      })
      .catch(error => {
        setLoading(false);
        console.log("login error:::", error);
        ShowErrorToast(error);
      });
  };
  console.log("isUser", isUser);

  return (
    <div className="signin-content-right" style={{ padding: "80px 80px" }}>
      <h2 className="h2 fw-bold">{t("common:signIn")}</h2>
      <Form>
        <Row>
          <Col className="col-12">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                onChange={handleInputChange}
                name={"email"}
                type="email"
                placeholder={t("common:email")}
              />
              <Form.Text className="errorMsg">{errorState["email"]}</Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                onChange={handleInputChange}
                name={"password"}
                type="password"
                placeholder={t("common:password")}
              />
              <Form.Text className="errorMsg">
                {errorState["password"]}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="col-4">
            <span className={classes.signinastext}>{t("common:signInAs")}</span>
          </Col>
          <Col className="col-4 d-flex align-items-center">
            <input
              className="discounttype"
              type="radio"
              name="user_type"
              value={"merchant"}
              checked={loginState.user_type === "merchant"}
              onChange={handleInputChange}
            />
            <span className="discounttype ms-1 me-1">
              {t("common:merchant")}
            </span>
          </Col>
          <Col className="col-4 d-flex align-items-center">
            <input
              type="radio"
              name="user_type"
              value={"user"}
              checked={loginState.user_type === "user"}
              onChange={handleInputChange}
            />
            <span className="discounttype ms-1 me-1">{t("common:user")}</span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Button
              bsPrefix="site-btn rounded-btn"
              onClick={handleLogin}
              className=""
              variant="primary"
            >
              {t("common:signIn")}
            </Button>
          </Col>
        </Row>
        {/* <Row>
                    <Col>
                        <SocialLogin user_type={loginState.user_type}/>
                    </Col>
                </Row> */}
        <Row>
          <Col>
            <Button
              onClick={() => {
                navigate(BASPATH + "auth/forgot-password");
              }}
              variant="link"
            >
              {" "}
              {t("navigate:forgotPassword")}{" "}
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="loader-wrapper mt-2">
        <LoadingSpinner loading={loading} />
      </div>
    </div>
  );
};

export default SignInForm;
