export default {
  first_name: 'Veuillez saisir un prénom.',
  last_name: 'Veuillez saisir un nom de famille.',
  email: 'S\'il vous plaît, mettez une adresse email valide.',
  password: 'Entrer un mot de passe valide s\'il vous plait.',
  current_Password: 'Veuillez saisir un mot de passe actuel et valide.',
  new_Password: 'Veuillez saisir un nouveau mot de passe valide.',
  password_confirmation_new: 'Le mot de passe actuel et le nouveau mot de passe ne correspondent pas.',
  password_confirmation: 'Le mot de passe et la confirmation du mot de passe ne correspondent pas.',
  phone_number: 'S\'il vous plaît entrer un numéro de téléphone valide.',
  id_number: 'Veuillez saisir un numéro d\'identification.',
  marital_status: 'Veuillez sélectionner un état civil.',
  no_of_child: 'Veuillez sélectionner un nombre d\'enfants.',
  occupation: 'Veuillez sélectionner une profession.',
  education_level: 'Veuillez sélectionner un niveau d\'éducation.',
  house_number: 'Veuillez entrer un numéro de maison',
  city: 'Veuillez entrer la ville.',
  coupon_title: 'Veuillez saisir le titre du coupon.',
  business_name: 'Veuillez saisir le nom de l\'entreprise.',
  registration_number: 'Veuillez saisir un numéro d\'enregistrement.',
  website:'Veuillez saisir une URL.',
  location_url:'Veuillez saisir l\'URL de l\'emplacement.',
  business_activity:'Veuillez sélectionner une activité commerciale.',
  businessSector:'Veuillez sélectionner le secteur d\'activité.',
  establishment_year:'Veuillez saisir une année d\'établissement valide.',
  business_hours:'Veuillez entrer les heures d\'ouverture.',
  street_address_name:'Veuillez saisir le nom de l\'adresse postale.',
  street_number:'Veuillez entrer un numéro de rue.',
  district:'Veuillez entrer la région de votre province nationale.',
  code:'Veuillez entrer votre code.',
  oneTimePassword:'Veuillez saisir un mot de passe à usage unique (OTP).',
  terms:'Veuillez ajouter les termes et conditions',
  
  coupon_description: "Veuillez saisir la description du coupon",
  coupon_code: "Veuillez entrer un code promo",
  qrcode_url: "Veuillez saisir une URL de code QR",
  discount_amount: "Veuillez saisir un montant de réduction",
  discount_type: "Veuillez sélectionner un type de réduction",
  discount_percent: "La remise maximale doit être de 100 %..",
  location_id: "Veuillez sélectionner un emplacement",
  category_id: "Veuillez sélectionner une catégorie",
  delete_coupon: "Êtes-vous sûr de vouloir supprimer ce coupon ?",
  
  blogs_not_found:'Aucun blog trouvé',
  coupons_not_found:'Aucun coupon trouvé',
  feed_not_found:'La collection de la Torah n\'a pas été trouvée',
  notification_not_found:'Aucun message trouvé',
  recent_comment_not_found:'Dernier commentaire introuvable',
  optional:'Facultatif',
  review_not_found:'Aucun avis trouvé',
  review:'Aucun avis trouvé',
  no_chat_rooms:'Aucun message trouvé !',
  user_type:'Veuillez sélectionner un type d\'utilisateur',
  users_not_found:'Utilisateurs introuvables',
  publicFeed_not_found:'Flux public introuvable',
  gender_error: 'Veuillez sélectionner le sexe.',
};