import React, { useEffect, useRef, useState, } from 'react'
import Modal from 'react-bootstrap/Modal';

import { RabiImage, close } from '../../constants/images';


const RabiImageModal = () => {

    const [modalVisible, setModalVisible] = useState(true)

    const handleClose = () => setModalVisible(!modalVisible);

    setTimeout(() => {
         setModalVisible(false)
    }, 6000);
 //className='custom-modal-size'
    return (
        <>
            <Modal  size='lg' show={modalVisible} onHide={handleClose}>
                {/* <Modal.Header closeButton>
                </Modal.Header> */}
                <Modal.Body className='p-0' style={{textAlign:'center', width: '100%'}}>
                    <div className='p-0'>
                        <img src={RabiImage}  height={'100%'} width={'100%'} />
                        <img className='pointer' onClick={handleClose} src={close} height={20} width={20} style={{top: 20, position: 'absolute', right: 10}} />
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default RabiImageModal