import { routes as login } from "./views/auth";
import { routes as dashboard } from "./views/dashboard";
import { routes as userCoupon } from "./views/userCoupon";
import { routes as merchantCoupon } from "./views/merchantCoupon";
import { routes as feeds } from "./views/publicfeed";
import { routes as blogs } from "./views/blog";
import { routes as about } from "./views/about";
import { routes as profile } from "./views/userProfile";
import { routes as smartcard } from "./views/smartCard";
import { routes as setting } from "./views/setting";
import { routes as terms } from "./views/terms";
import { routes as notification } from "./views/notification";
import { routes as chat } from "./views/chat";
import { routes as social } from "./views/social";
import { routes as thankyou } from "./views/thankYou";
import {
  routesLiveStreamBroadcaster,
  routesLiveStreamViewer
} from "./views/liveStream";
import { routesPlans } from "./views/plans";

export const merchantRoutes = [
  ...dashboard,
  ...merchantCoupon,
  ...feeds,
  ...blogs,
  ...about,
  ...profile,
  ...smartcard,
  ...setting,
  ...terms,
  ...notification,
  ...chat,
  ...social,
  ...routesLiveStreamViewer,
  ...routesPlans
  // ...routesLiveStreamBroadcaster
]; //

export const userRoutes = [
  ...dashboard,
  ...userCoupon,
  ...feeds,
  ...blogs,
  ...about,
  ...profile,
  ...smartcard,
  ...setting,
  ...terms,
  ...notification,
  ...chat,
  ...social,
  ...routesLiveStreamViewer
  // ...routesLiveStreamBroadcaster
]; //

export const authRoutes = [...login];
