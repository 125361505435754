import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { validateEmail } from '../../utils/common'
import CustomInput from '../UI/CustomInput'
import TermsModal from '../UI/termsModel'
import SocialLogin from './SocialLogin'
// import TermsModal from '../UI/termsModel'


const MerchantForm = ({ userState, handleInputChangeUser, handleRegister }) => {
    const { t, i18n } = useTranslation()

    const [errorState, setErrors] = useState({});

    const [agree, setAgree] = useState(false);
    const [showTermspopup, setShowTermspopup] = useState(false);

    const checkboxHandler = () => {
        setShowTermspopup(!showTermspopup)
    }

    const validateForm = () => {
        let fields = userState;
        let errors = {};
        let formIsValid = true;

        console.log('loginState', fields)

        if (!fields["business_name"]) {
            formIsValid = false;
            errors["business_name"] = t("error:business_name");
        }
        if (!fields["registration_number"]) {
            formIsValid = false;
            errors["registration_number"] = t("error:registration_number");
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = t("error:email");
        }

        if (!validateEmail(fields["email"])) {
            formIsValid = false;
            errors["email"] = t("error:email");
        }

        if (!fields["password"] || fields["password"].length < 6) {
            formIsValid = false;
            errors["password"] = t("error:password");
        }
        if (!fields["password_confirmation"].trim() || fields["password"] !== fields["password_confirmation"]) {
            formIsValid = false;
            errors["password_confirmation"] = t("error:password_confirmation");
        }
        // if (!fields["website"]) {
        //     formIsValid = false;
        //     errors["website"] = t("error:website");
        // }

        console.log('formIsValid >>>>>>>>>>>>', formIsValid)
        setErrors(errors);
        return formIsValid;
    };

    useEffect(() => {
        setErrors({})
    }, [i18n.language])

    const onSubmit = (e) => {
        console.log('userState >>>>>>>>>>>>', userState)
        e.preventDefault();
        if (validateForm()) {
            handleRegister(e)
        }
    }

    const onCallBack = () => {
        console.log('onCallBack', )
        setAgree(true);
    }

    console.log('agree', agree)

    return (
        <>
            <CustomInput name={'business_name'}
                value={userState.business_name}
                placeholder={t('common:businessName')}
                onChange={handleInputChangeUser}
                errormsg={errorState["business_name"]}
                type={'text'} />
            <CustomInput name={'registration_number'}
                value={userState.registration_number}
                placeholder={t('common:regNo')}
                onChange={handleInputChangeUser}
                errormsg={errorState["registration_number"]}
                type={'number'} />
            <CustomInput name={'email'}
                value={userState.email}
                placeholder={t('common:emailAddress')}
                onChange={handleInputChangeUser}
                errormsg={errorState["email"]}
                type={'email'} />
            <CustomInput name={'password'}
                value={userState.password}
                placeholder={t('common:createPassword')}
                onChange={handleInputChangeUser}
                errormsg={errorState["password"]}
                type={'password'} />
            <CustomInput name={'password_confirmation'}
                value={userState.password_confirmation}
                placeholder={t('common:confirmPassword')}
                onChange={handleInputChangeUser}
                errormsg={errorState["password_confirmation"]}
                type={'password'} />
            <CustomInput name={'phone_number'}
                value={userState.phone_number}
                placeholder={t('common:phoneNumber') + " (" + t("error:optional") + ")"}
                onChange={handleInputChangeUser}
                errormsg={errorState["phone_number"]}
                type={'number'} />
            <CustomInput name={'website'}
                value={userState.website}
                placeholder={t('common:website')}
                onChange={handleInputChangeUser}
                errormsg={errorState["website"]}
                type={'text'} />

            <div className='d-flex p-2 align-center' >
                <input checked={agree} type="checkbox" id="agree" />
                <label onClick={() => { checkboxHandler() }} className='ms-2 me-2 pointer' htmlFor="agree"> <b>{" " + t('navigate:termsncondition')}</b></label>
            </div>

            <TermsModal
                modalVisible={showTermspopup}
                setModalVisible={setShowTermspopup}
                onCallBack={onCallBack}
            />

            {/* <Row>
                <Col>
                    <SocialLogin />
                </Col>
            </Row> */}

            <Row className='mt-2'>
                <Col>
                    <Button disabled={!agree} style={{
                        opacity: !agree ? .6 : 1
                    }} bsPrefix="site-btn rounded-btn" onClick={onSubmit} className="" variant="primary">{t('navigate:register')}</Button>
                </Col>
            </Row>


        </>
    )
}

export default MerchantForm