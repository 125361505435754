import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

 
const ProfileComponent = Loadable({
  loader: () => import("./components/profile"),
  loading: Loading
});
 
export const routes = [
  {
    path: BASPATH + "user-profile",
    exact: true,
    name: "userProfile",
    element: ProfileComponent
  },
];

export * from "../auth/components";
