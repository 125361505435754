export default {
    home: 'בית',
    login: 'התחברות',
    register: "להרשמה",
    settings: 'הגדרות',
    signin: 'להתחבר',
    otpView: 'OtpView',
    myCoupons: 'הקופונים שלי',
    addCoupon: 'הוסף קופון',
    editCoupon: 'ערוך קופון',
    myCouponsClient: 'הקופונים שלי',
    backCouponsList: 'בחזרה לרשימת הקופונים',
  //backPublicFeed: 'חזרה לעדכון ציבורי',
    backPublicFeed: 'חזרה לאספי תורה',
    couponStatistics: 'סטטיסטיקה של הקופונים',
    backBlogs: 'חזרה לבלוגים',
    myProfile:"הפרופיל שלי",
    myProfileMerchant:"הפרופיל שלי", 
    smartCitizenDebitCard: "תושב חכם ישראל", 
    aboutSmartCitizenApp:"אודותינו",
    notification:"התראות",
    help:"עֶזרָה",
    termsncondition:"תנאים והגבלות",
    logout:"להתנתק",
    forgotPassword: 'שכחת את הסיסמא',
    resetPassword: 'לאפס את הסיסמה',
    generateOtp: 'צור קוד',
    couponReview: 'ביקורות',
    messages:"דואר נכנס",
    accountPrivacy:"פרטיות חשבון",
    settingsPrivacy:"הגדרות ופרטיות",
    postDetail:"פירוט העדכון הציבורי",
    socialHome:"קיר ציבורי",
    postCreate:"צור פוסט",
    postProfile:"פרופיל חברתי",
    chat:"דואר נכנס",
    blockedUsers:"משתמשים חסומים",
    connectedUsers:"משתמשים מחוברים",
    likes:"אוהב",




 //web app text
    guestuser: 'משתמש אורח',
    social: "חֶברָתִי",
    plans: "Plans",
  };