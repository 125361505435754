import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

 
const CouponsComponent = Loadable({
  loader: () => import("./components/coupon/coupons"),
  loading: Loading
});
const CouponDetailComponent = Loadable({
  loader: () => import("./components/coupon-detail/couponDetail"),
  loading: Loading
});
const MyCouponsComponent = Loadable({
  loader: () => import("./components/mycoupon"),
  loading: Loading
});
const CouponReviewComponent = Loadable({
  loader: () => import("./components/coupon-review/reviewDetails"),
  loading: Loading
});

export const routes = [
 
  {
    path: BASPATH + "coupons",
    exact: true,
    name: "coupons",
    element: CouponsComponent
  },
  
  {
    path: BASPATH + "myCoupons",
    exact: true,
    name: "MyCoupons",
    element: MyCouponsComponent
  },
  {
    path: BASPATH + "coupons-detail/:itemId",
    exact: true,
    name: "CouponDetail",
    element: CouponDetailComponent
  },
  {
    path: BASPATH + "coupons-detail",
    exact: true,
    name: "CouponDetail",
    element: CouponDetailComponent
  },
  {
    path: BASPATH + "couponReview",
    exact: true,
    name: "couponReview",
    element: CouponReviewComponent
  },
   
  
];

export * from "./components";
