import React from 'react'
import {  Form } from "react-bootstrap"

const CustomInput = ({ name, value, onChange, type = "text", placeholder, errormsg }) => {
    return (
            <Form.Group className="mb-3" controlId={name}>
                <Form.Control value={value}
                  //  inputMode={type}
                    onChange={onChange}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                     />
                <Form.Text className="errorMsg">{errormsg}</Form.Text>
            </Form.Group>
    )
}

export default CustomInput