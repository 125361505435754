import { IS_CLIENT_SERVER } from "./appConfig";

export default {
  api: {
    // baseURL: 'http://15.207.152.121/smartapp/api/',
    baseURL: IS_CLIENT_SERVER
      ? "https://toshavhaham.co.il/smartapp/api/"
      : "https://knp-tech.in/smartapp/api/",
    //baseURL: IS_CLIENT_SERVER ? 'https://toshavhaham.co.il/smartapp/api/' : 'http://15.207.152.121/smartapp/api/',
    baseAdminUrl: "https://toshavhaham.co.il/smartapp/",
    timeout: 25000
  }
};

//http://15.207.152.121/api/apple/callback

//https://toshavhaham.co.il/smartapp/api/
//http://78.47.88.161/smartapp/api/
