import React from "react";
import { Navbar, Nav, Col, Button } from "react-bootstrap";

// Component CSS
import classes from "./Navigation.module.css";

// User Icon
import user from "../../assets/user.png";
import { useTranslation } from "react-i18next";
import { UseAppContext } from "../../contexts/AppProvider";

const Navigation = ({ setSignUp }) => {
  const { t, i18n } = useTranslation();
  const { width, isMobile } = UseAppContext();
  return (
    <>
      <Col className="d-flex align-items-center justify-content-end">
        <Navbar>
          <Nav>
            <span className={classes.btn} onClick={() => setSignUp(false)}>
              {t("common:signIn")}
            </span>
            <span>|</span>
            <span className={classes.btn} onClick={() => setSignUp(true)}>
              {t("navigate:register")}
            </span>
          </Nav>
        </Navbar>
      </Col>
    </>
  );
};

export default Navigation;
