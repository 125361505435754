export default {
  home: 'Home',
  login: 'Login',
  register: "Register",
  settings: 'Settings',
  signin: 'Sign In',
  otpView: 'OtpView',
  myCoupons: 'My Coupons',
  addCoupon: 'Add Coupon',
  editCoupon: 'Edit Coupon',
  myCouponsClient: 'My Coupons',
  backCouponsList: 'Back to Coupons List',
  backPublicFeed: 'Back to Torah collections',
  couponStatistics: 'Statistics of the Coupons',
  backBlogs: 'Back to Blogs',
  myProfile: "My Profile",
  myProfileMerchant: "Profile",
  smartCitizenDebitCard: "תושב חכם Israel 18 Debit Card",
  aboutSmartCitizenApp: "About תושב חכם Israel 18",
  notification: "Notifications",
  help: "Help",
  termsncondition: "Terms & Conditions",
  logout: "Logout",
  forgotPassword: 'Forgot Password',
  resetPassword: 'Reset Password',
  generateOtp: 'Generate OTP',
  couponReview: 'Reviews',
  messages: "E-mail",
  accountPrivacy: "Account Privacy",
  settingsPrivacy: "Settings and Privacy",
  postDetail: "Public Feed Detail",
  socialHome: "Public Feed",
  postCreate: "Create Post",
  postProfile: "Social Profile",
  chat: "Chat",
  blockedUsers: "Blocked Users",
  connectedUsers: "Connected Users",
  likes: "Likes",




  //web app text
  guestuser: 'Guest User',
  social: "Social",
  plans: "Plans",
};
