import React, { useContext, useEffect, useState } from "react";

const AppContext = React.createContext();

export function UseAppContext() {
  return useContext(AppContext);
}

export function AppContextProvider({ children }) {
  const [appCount, setAppCount] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const isMobile =  false //width <= 1024; //width <= 768;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);



  return (
    <AppContext.Provider value={{ width, height, isMobile }}>
      {children}
    </AppContext.Provider>
  );
}
 
  