import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../../components/Layout/LoadingSpinner';
import { getFeeds, postFeedComment, postFeedLike } from '../../../../redux/actions/feedActions';
import { ShowErrorToast, ShowToast } from '../../../../utils/common';
import PublicFeedItem from './publicFeedItem';

import './publicFeedList.css'
import HeadTags from '../../../../components/Layout/Seo';


const LIMIT = 10
const PublicFeedList = ({ searchQuery = "" }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const auth = useSelector(({ auth }) => auth);
  const feed = useSelector(({ feed }) => feed);
  const authUser = auth.data

  const [feeds, seFeeds] = useState([])
  const resetData = useRef(false);
  const PublicFeedRef = useRef();

  const [offset, setOffset] = useState(0)
  const [AddComment, setAddComment] = useState([])


  useEffect(() => {
    PublicFeedRef.current = true;
    return () => PublicFeedRef.current = false;
  }, []);

  useEffect(() => {
    //  getPublicFeeds()
    resetData.current = true;
    setOffset(0)
    seFeeds([])
    setTimeout(() => {
      console.log('searchQuery', searchQuery)
      fetchResult()
    }, 700);
  }, [searchQuery])

  const fetchResult = () => {
    let pageToReq = offset;
    if (resetData.current) {
      pageToReq = 0;
    }
    const params = {
      search: searchQuery,
      offset: pageToReq,
      limit: LIMIT,
      user_id: authUser.user_id? authUser.user_id : ""
    }
    console.log('params', params)
    if (PublicFeedRef.current)
      dispatch(getFeeds(params, authUser.token))
        .then((res) => {
          // setIsButtonClick(false)
          console.log('res.data', res.data)
          //seFeeds([])
          if (PublicFeedRef.current)
            if (res.status === 200) {
              if (resetData.current) {
                seFeeds([])
                seFeeds(res.data)
                setOffset(LIMIT)
                resetData.current = false;
              } else {
                if (res.data && res.data.length > 0) {
                  seFeeds(feeds.concat(res.data))
                  setOffset(offset + LIMIT)
                }

              }
            }
        }).catch((error) => {
          console.log('res.error', error)
        })
  };



  const handlePick = (emojiObject, name) => {
    console.log(emojiObject)
    let newArr = [...AddComment];
    newArr[name] = (newArr[name] ? newArr[name] : '') + emojiObject.emoji
    setAddComment(newArr)
  }
  const [loading, setLoading] = useState(false);
  // const [photo, setPhoto] = useState(null);

  //console.log('feeds', feeds)


  const onChangeValue = (name, e) => {
    let newArr = [...AddComment];
    newArr[name] = e.target.value
    console.log('newArr', name, e.target.value, newArr)
    setAddComment(newArr)
  };




  const onImageChange = (item, index, image) => {
    console.log('onImageChange', item, index, image)
    postComment(item.public_feed_id, AddComment[index], image)
  };


  const postComment = async (itemId, comment, image) => {
    // setLoading(true)
    const url = image ? URL.createObjectURL(image) : ''
    const params = {
      public_feed_id: itemId,
      comment: comment ? comment : '',
      image: image
    }
    console.log('param params', params)
    var data = new FormData();
    const fileName = image ? image.name : ''
    Object.keys(params).forEach(key => {
      if (key === 'image') {
        if (image) {
          data.append('image', image);
        } else {
          data.append(key, params[key]);
        }
      } else {
        data.append(key, params[key]);
      }
    });

    console.log('param options', data)
    // return
    setLoading(true)
    dispatch(postFeedComment(data, authUser.token))
      .then((res) => {
        console.log('postFeedComment res', res.status)
        setLoading(false)
        if (res.status === 200) {
          // setPhoto(null);
          setAddComment([])
        }
        ShowToast(res && res.message)
      }).catch((error) => {
        console.log('error', error)
        setLoading(false)
        ShowErrorToast(error)
      })
  }


  const feedLike = (isLike, itemId) => {
    console.log('isLike, itemId', isLike, itemId)
    const params = {
      public_feed_id: itemId,
      is_like: isLike ? 1 : 0,
    }
    console.log('params', params)
    dispatch(postFeedLike(params, authUser.token))
      .then((res) => {
        //console.log('res', res)
        //   setIsButtonClick(false)
        if (res.status === 200) {
          const itemList = [...feeds];
          const itemFeed = itemList.find(a => a.public_feed_id === itemId);
          itemFeed.is_like = isLike ? 1 : 0;
          itemFeed.feed_like_count = isLike ? itemFeed.feed_like_count + 1 : itemFeed.feed_like_count - 1
          // console.log('itemFeed', itemFeed)
          seFeeds(itemList);
          // seRefresh(!refresh)
        }
        // //Toast.show(res.message, Toast.SHORT);
      }).catch((error) => {
        //  setIsButtonClick(false)
      })
  }

  return (
    <div className=''>
     
      <ul className="row">
        {
          feeds && feeds.map((item, index) => {
            return <div key={index}> <PublicFeedItem
              item={item}
              index={index}
              i18n={i18n}
              AddComment={AddComment}
              onChangeValue={onChangeValue}
              postComment={postComment}
              feedLike={feedLike}
              onImageChange={onImageChange}
            />

            </div>
          })

        }
      </ul>
      
      <div className="loader-wrapper">
        {
          !feed.isRequesting && feeds.length <= 0 ? <span className="text-blogs-all">
            {t('error:feed_not_found')}
          </span> : null
        }

      </div>

      <div
        className="loader-wrapper"
      // style={{ left: "40%" }}
      >
        <LoadingSpinner loading={feed.isRequesting} />
      </div>
      <div className="feed-load-more-box pointer" onClick={fetchResult}>
        <span className="text-feed-all">
          {t("common:loadMore")}
        </span>
      </div>
    </div>
  )
}

export default PublicFeedList