import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

 
const Chat = Loadable({
  loader: () => import("./components/chat"),
  loading: Loading
});
 
export const routes = [
  {
    path: BASPATH + "chat",
    exact: true,
    name: "chat",
    element: Chat
  },
  
];

export * from "../auth/components";
