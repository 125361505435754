export default {

  email: "البريد الإلكتروني",
  password: "كلمة المرور",
  signIn: "تسجيل الدخول",
  register: "يسجل",
  merchant: "تاجر",
  user: "المستخدم",
  fname: "الاسم الشخصي",
  lname: "اسم العائلة",
  businessName: "الاسم المستخدم",
  regNo: "رقم التسجيل",
  website: "موقع الكتروني",
  location_url: "عنوان URL للموقع",
  emailAddress: "عنوان البريد الالكترونى",

  createPassword: "أنشئ كلمة مرور",
  confirmPassword: "تأكيد كلمة المرور",
  phoneNumber: "رقم الهاتف",
  idNumber: "رقم الهوية",
  idNumbermsg: "سيتعين عليك إضافة رقم معرف حكومي للتحقق من ملف التعريف. \ n سيتعين عليك زيارة مكتب بنك البريد المحلي وتقديم معلومات التسجيل الخاصة بك بما في ذلك إثبات الهوية الحكومية الذي أدخلته في التطبيق أثناء التسجيل. \ n بعد الحصول على التحقق معلومات المستخدمين من مكتب بنك البريد ، سوف نتحقق من ملفك الشخصي.",
  streetAddName: "اسم  الشارع",
  streetNo: "رقم الشارع",
  houseNo: "رقم البيت",
  city: "مدينة",
  district: "المنطقة",
  from: "من الساعة",
  to: "حتى الساعة",
  addBusinessLogo: "إضافة شعار العمل",
  businessLogo: "شعار الأعمال",
  businessHours: "ساعات العمل",


  validEmail: "الرجاء إدخال بريد إلكتروني صحيح.",
  validPassword: "الرجاء إدخال كلمة مرور صالحة.",

  signInAs: "تسجيل الدخول باسم",
  donHaveAccount: "ليس لديك حساب؟",
  alreadyHaveAccount: " لديك حساب مسبقا؟",
  haventReceivedOTP: "لم تستلم رمز حتى الآن؟",
  registerAs: "سجل باسم",
  verify: "تأكيد",
  resend: "للبداية",
  next: "التالي",
  back: "للخلف",


  personalInformation: "معلومات شخصية",
  morePersonalInfo: "المزيد من المعلومات الشخصية",
  moreInfoBusiness: "مزيد من المعلومات حول عملك",
  businessInformation: "معلومات العمل",
  contactDetails: "بيانات المتصل",
  businessActivity: "النشاط التجاري",
  businessSector: "قطاع الأعمال",
  establishmentYear: "سنة التأسيس",

  maritalStatus: "الحالة الحالة الإجتماعية",
  numbersofChildren: "عدد الاطفال",
  occupation: "إشغال",
  educationLevel: "مستوى التعليم",

  VerifyPhoneNumber: "التحقق من عنوان البريد الإلكتروني",
  enterOtp: "أدخل كلمة المرور لمرة واحدة (OTP)",
  otpMsg: "لقد أرسلنا لك كلمة المرور لمرة واحدة (OTP) إلى عنوان بريدك الإلكتروني المسجل.",


  languageSelector: 'اختر لغتك',

  changePassword: "غير كلمة السر",
  currentPassword: "كلمة المرور الحالية",
  newPassword: "كلمة السر الجديدة",
  chooseLanguage: "اختر اللغة",
  languageSettings: "اعدادات اللغة",
  save: "يحفظ",
  cancel: "يلغي",
  removeAccount: "إزالة الحساب",
  ok: "موافق",

  coupons: "كوبونات",
  publicFeeds: "مجموعات التوراة",
  feeds: "الصفحة الرئيسية",
  blogs: "المدونات",
  search: "يبحث",
  //couponsMsg: "إظهار جميع القسائم بالقرب من تل أبيب",
  couponsMsg: "إظهار جميع القسائم",
  blogsMsg: "عرض كل المدونات",

  couponTitle: "خصم ثابت 20٪ في ماكدونالدز",
  couponDesc: "ستظهر هنا شروط وأحكام لوريم إيبسوم",
  couponCode: "MCD20",
  validaty:"صلاحية:",
  couponExpDate: "31st May 2019",

  addBtn: "يضيف",
  updateBtn: "تحديث",
  editBtn: "يحرر",
  viewBtn: "منظر",
  saveBtn: "يحفظ",
  statsBtn: "احصائيات",

  // feedsSearch:"",
  feedTitle: "حدث إبداعات الحلم",
  feedDesc: "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة.",

  addComment: "أضف تعليق",

  blogTitle: "نزوة فضولية",
  blogDesc: "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة.",
  category: "فئة",
  sports: "رياضة",
  report: "تقرير",
  reportMessage: "هل أنت متأكد أنك تريد الإبلاغ عن هذا المحتوى؟",
  readMore: "اقرأ أكثر",
  readLess: "أقرأ أقل",
  recentComments: "احدث التعليقات",

  coupon_title:"عنوان القسيمة",
  coupon_title_ab:"عنوان القسيمة (Arabic)",
  coupon_title_he:"عنوان القسيمة (Hebrew)",
  coupon_description:"وصف القسيمة",
  coupon_code:"رمز الكوبون",
  discount_amount:"مقدار الخصم",
  discount_type:"نوع الخصم",
  qrcode_url:"QR كود Url",
  storelocation:"حدد موقع المتجر",
  storecategory:"اختر الفئة",
  create_date:"تاريخ الإنشاء",
  expiry_date:"تاريخ انتهاء الصلاحية",
  term_condition:"الشروط والأحكام",
  
  
  dabitcardTitle:"لقد تقدمت بالفعل بطلب للحصول على بطاقة الخصم المباشر Israel 18. يرجى التحقق أدناه من حالة طلب بطاقة الخصم الذكي Israel 18.",
  dabitcardTitle1:"كونك مستخدمًا قيّمًا لتطبيق Israel 18 يسعدنا أن نوفر لك امتياز بطاقة الخصم Israel 18. انقر فوق  لإرسال طلبك للحصول على بطاقة الخصم المباشر Israel 18 ؛ أو انقر فوق Skip للمتابعة إلى تطبيق Israel 18",
  addTerms:"أضف الشروط والشرط",

  apply_filters:"تطبيق المرشحات",
  apply:"يتقدم",
  clear_all:"امسح الكل",
  categories:"فئات",
  location:"موقع",
  flat:"مسطحة",
  percentage:"نسبة مئوية",
  valid_all_stores:"صالح في جميع المتاجر",
  my_coupons:"القسائم الخاصة بي",

  thankyou:"شكرا لك",
  thankyou_msg:"لقد تلقينا طلبك للتسجيل كتاجر باستخدام تطبيق Israel 18. نحن بصدد مراجعة طلبك. يجب أن تسمع منا في غضون 72 ساعة.",
  applyAgain:"تقدم مرة أخرى",
  cancelApplication:"إلغاء التطبيق",
  status:"حالة",
  skip:"يتخطى",
  applyNow:"قدم الآن",
  ok:"موافق",
  activeCoupons:"القسائم النشطة",
  inactiveCoupons:"قسائم غير نشطة",
  couponsAddedBy:"أضيفت القسائم بواسطة",
  couponsUsedBy:"القسائم المستخدمة من قبل",
  totalShare:"إجمالي حصة",
  whatsapp:"واتس آب",
  shareOnWhatsApp:"مشاركه فى واتس آب",
  shareOnEmail:"شارك على البريد الإلكتروني",
  share:"يشارك",
  accept:"قبول",
  code:"رمز",
  OneTimePassword:"كلمة المرور لمرة واحدة (OTP)",
  selectItem:"حدد البند",
  review: "مراجعة",
  reviewTitle: "كيف كانت تجربتك",
  submit:"يُقدِّم",
  chooseLibrary:"اختر من المكتبة",
  image:"صورة",
  video:"فيديو",
  loginwith:"تسجيل الدخول مع",
  or:"أو",
  document:"وثيقة",
  gallery:"صالة عرض",
  audio:"صوتي",
  Typemessage:"اكتب رسالة",

  media:"وسائط",
  shareMedia:"مشاركة الصور ومقاطع الفيديو",
  photos:"الصور",
  sharePhotos:"مشاركة الصور",
  videos:"أشرطة فيديو",
  shareVideos:"مشاركة مقاطع الفيديو",
  shareAudios:"مشاركة الصوت",
  file:"ملف",
  shareFile:"مشاركة الملفات",
  selectFile:"حدد ملف",
  select:"يختار",
  block:"حاجز",
  photo:"صورة",

  logout:"تسجيل خروج",
  logout_message:"هل ترغب بالخروج؟",
  delete_chat:"احذف الرسالة",
  delete_chat_message:"هل تريد إزالة الرسالة؟",
  delete_account_name:"إزالة الحساب",
  delete_account_message:"هل تريد حذف الحساب؟",
  delete_post:"إزالة المشاركة",
  delete_post_message:"هل تريد إزالة المشاركة؟",
  block_user:"مستخدم محضور",
  block_user_message:"هل تريد حظر المستخدم؟",

  message:"رسالة",
  edit_profile:"تعديل الملف الشخصي",
  posts:"دعامات",
  connects:"يربط",
  connect:"يتصل",
  connected:"متصل",
  account_privacy:"الخصوصية حساب",
  public:"عام",
  private:"خاص",
  private_account:"حساب خاص",
  blocked_users:"مستخدمين محجوبين",
  privacy_msg1:"عندما يكون حسابك عامًا، يمكن لأي شخص رؤية ملفك الشخصي ومنشوراتك، داخل أو خارج توشاف ههام، حتى لو لم يكن لديه حساب في توشاف ههام.",
  privacy_msg2:"عندما يكون حسابك خاصًا، فإن الزملاء الذين توافق عليهم فقط هم من يمكنهم رؤية ما تشاركه، بما في ذلك الصور أو مقاطع الفيديو وقوائم الزملاء الخاصة بك.",
  go_back:"عُد",
  create_post:"إنشاء مشاركة",
  post_upload_msg:"انقر لتحميل الصورة والفيديو الخاص بك",
  post_error_msg:"من فضلك قم بتحميل الصورة أو الفيديو الخاص بك",
  comments:"تعليقات",
  delete:"يمسح",
  confirm:"يتأكد",
  under_maintenance:"التطبيق تحت الصيانة حاليا.",
  postCreateMsg:"اكتب شرحا...",
  disconnect:"قطع الاتصال",
  unblock:"رفع الحظر",
  shabBatTital:"إعداد الملف الشخصي كحافظ السبت",
  shabBatDesc:"أرغب في التعرف على المعلنين الملتزمين بيوم السبت و/أو أولئك الذين يعرّفون حساباتهم على أنها ملتزمة بيوم السبت والتائبين.",
  on:"نشط",
  off:"أطفئ",
  bio:"السيرة الذاتية",
  user_name:"اسم المستخدم",
  link:"وصلة",
  views:"الآراء",
  view:"منظر",
  shabBat:"سبت",
  accountIsPrivate:"هذا الحساب خاص",
  locationNotFound: 'لم يتم العثور على الموقع',
  requested:'مطلوب',
  seemore:'شاهد المزيد',
  broadcastEnd:'انتهى البث',
  cancelRequsted:'إلغاء الطلب',
  cancelRequsted_message:"هل تريد إلغاء الطلب؟",
  gender:'جنس',
  yourcity: "اختر مدينتك",
  profileComplete:'الملف الشخصي الكامل',
  profileComplete_msg:'يرجى استكمال الملف الشخصي الخاص بك.',
  isLive:"حي!",
  goToLive:"اذهب إلى العيش",
  leaveRoom:"غادر الغرفة",
  live:"يعيش",

  filters: "Filters",
  allConversion: "All Conversations",
  unread:"Unread",
  read:"Read",
  favorites:"Favorites",
  archive:"Archive",
  trash:"Trash",

  
  //web app text
  popularCategories: "الفئات الشعبية",
  latestCoupon: "أحدث الكوبونات",
  latestBlogs: "أحدث المدونات",
  recentPost: "المشاركات الاخيرة",
  copyCode: "رمز النسخ",
  couponMsg: "استخدم رمز القسيمة عند الخروج",
  loadMore: "تحميل المزيد",
  seeAllBlogs: "انظر جميع المدونات",
  upto: "يصل إلى",
  off: "عن",
  downloadApp: "قم بتنزيل التطبيق الآن",
  allRightReserved: "2023 © توشاف هشام جميع الحقوق محفوظة",
  loginRequire: "يجب أن تكون مسجلا",
  type:"يكتب",
  selectAudience:"حدد الجمهور",
  start: "يبدأ",

  };