
  export const COLORS = {
    // primary: '#29479F',
    // secondary: '#688dbd',
    // error: '#D32F2F',
    // text: '#FFFFFF',
    // textDark: '#505050',
    // textPlaceHolder: '#cfdced',
    // border: '#FFFFFF',
    // white: '#FFFFFF',
    // black: '#000000',
    // activeTab: '#1976D2',
    // inactiveTab: '#757575',

    primary: '#0D1B34',
    tabColor: '#505050',
    secondary: '#fff',
    headerBG: '#fff',
    error: '#D32F2F',
    text: '#FFF',
    textDark: '#0D1B34',
   // textDark: '#000080',
    textPlaceHolder: '#8696BB',
    borderColor: '#E2EAF2',
    white: '#fff',
    black: '#000',
    listItemBg:'#FAFAFA',      //9cb2cc  #F8F8F8
    listItemBgSecondary:'#E9ECF5',      //9cb2cc  #F8F8F8
    iconDark: '#FFF',
    iconSecondary: '#0D1B34',
    BtnBg:'#0D1B34',
    BtnText:'#fff',
    primaryBG:"#FAFAFA",
    secondaryBG:"#056dbb",
    primaryText:"#0D1B34",
    secondaryText:"#434b5d",
  };


    
  
 