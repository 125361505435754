import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";


const PublicFeedComponent = Loadable({
  loader: () => import("./components/public-feed"),
  loading: Loading
});
const PublicFeedDetailComponent = Loadable({
  loader: () => import("./components/public-feed-detail/publicFeedDetail"),
  loading: Loading
});

export const routes = [
  {
    path: BASPATH + "torah-collections",
    exact: true,
    name: "torah-collections",
    element: PublicFeedComponent
  },
  {
    path: BASPATH + "torah-collection-detail/:itemId",
    exact: true,
    name: "TorahCollectionDetails",
    element: PublicFeedDetailComponent
  },
  {
    path: BASPATH + "torah-collections-detail",
    exact: true,
    name: "torah-collections",
    element: PublicFeedDetailComponent
  },


];

export * from "./components";
