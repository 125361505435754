import * as React from "react";

export default function MainConent(props) {
  return (
    <div
      style={{
        margin: "0px 0px 0",
        background: "#F8F8F8",
        height: "90%"
        //  width: "fit-content",
      }}
    >
      <div style={{ background: "#F8F8F8" }}>{props.children}</div>
    </div>
  );
}
