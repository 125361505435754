import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

 
const AboutUsComponent = Loadable({
  loader: () => import("./components/settings"),
  loading: Loading
});
 
export const routes = [
  {
    path: BASPATH + "settings",
    exact: true,
    name: "settings",
    element: AboutUsComponent
  },
];

export * from "../auth/components";
