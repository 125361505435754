import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

const LoginComponent = Loadable({
  loader: () => import("./components/login/login"),
  loading: Loading
});

// const SignInComponent = Loadable({
//   loader: () => import("../../components/Auth/SignIn"),
//   loading: Loading
// });
const ForgotPasswordComponent = Loadable({
  loader: () => import("./components/forgot-password/forgot-password"),
  loading: Loading
});
const ResetComponent = Loadable({
  loader: () => import("./components/reset/reset"),
  loading: Loading
});
const OtpViewComponent = Loadable({
  loader: () => import("./components/otp-view/otpview"),
  loading: Loading
});
const ThankYouComponent = Loadable({
  loader: () => import("./components/thank-you/thankYou"),
  loading: Loading
});
const LandingComponent = Loadable({
  loader: () => import("./components/invite/landing"),
  loading: Loading
});
const ThankYouInviteComponent = Loadable({
  loader: () => import("./components/invite/thankYou"),
  loading: Loading
});


export const routes = [
  // {
  //   path:"/",
  //   exact: true,
  //   name: "Auth",
  //   element: LoginComponent
  // },
  {
    path: BASPATH + "auth/login",
    exact: true,
    name: "Auth",
    element: LoginComponent
  },
  {
    path: BASPATH + "auth/forgot-password",
    exact: true,
    name: "forgot-password",
    element: ForgotPasswordComponent
  },
  {
    path: BASPATH + "auth/reset-password",
    exact: true,
    name: "reset-password",
    element: ResetComponent
  },
  {
    path: BASPATH + "auth/otp-view",
    exact: true,
    name: "otp-view",
    element: OtpViewComponent
  },
  {
    path: BASPATH + "thank-you",
    exact: true,
    name: "thankYou",
    element: ThankYouComponent
  },
  {
    path: BASPATH + "invite",
    exact: true,
    name: "Invite",
    element: LandingComponent
  },
  {
    path: BASPATH + "thankyou",
    exact: true,
    name: "thankyou",
    element: ThankYouInviteComponent
  },
];

export * from "./components";
