export default {
  first_name: 'Please enter First Name.',
  last_name: 'Please enter Last Name.',
  email: 'Please enter valid Email Address.',
  password: 'Please enter valid password.',
  current_Password: 'Please enter valid Current Password.',
  new_Password: 'Please enter valid New Password.',
  password_confirmation_new: 'Current Password & New Password does not match.',
  password_confirmation: 'Password & Confirm Password does not match.',
  phone_number: 'Please enter valid Phone Number.',
  id_number: 'Please Enter ID Number.',
  marital_status: 'Please select Marital Status.',
  no_of_child: 'Please select Numbers of Childrens.',
  occupation: 'Please enter Occupation.',
  education_level: 'Please select Education Level.',
  house_number: 'Please enter House Number',
  city: 'Please enter City.',
  coupon_title: 'Please enter Coupon Title.',
  business_name: 'Please enter Business Name.',
  registration_number: 'Please enter Registration Number.',
  website:'Please enter Website.',
  location_url:'Please enter Location url.',
  business_activity:'Please select Business Activity.',
  businessSector:'Please select Business Sector.',
  establishment_year:'Please enter valid Establishment year.',
  business_hours:'Please enter Business Hours.',
  street_address_name:'Please enter Street Address Name.',
  street_number:'Please enter Street Number.',
  district:'Please enter your District.',
  code:'Please enter your Code.',
  oneTimePassword:'Please enter One Time Password (OTP).',
  terms:'Please add Terms & Conditions',
  
  coupon_description: "Please enter Coupon Description",
  coupon_code: "Please enter Coupon Code",
  qrcode_url: "Please enter QR code Url",
  discount_amount: "Please enter Discount Amount",
  discount_type: "Please select Discount Type",
  discount_percent: "Maximum discount should be 100%.",
  location_id: "Please select Location",
  category_id: "Please select Category",
  delete_coupon: "Are you sure you want to delete this coupon?",
  
  blogs_not_found:'Blogs not found',
  coupons_not_found:'Coupons not found',
  feed_not_found:'Torah collection not found',
  notification_not_found:'Notifications not found',
  recent_comment_not_found:'Comments not found',
  optional:'Optional',
  review_not_found:'Reviews not found',
  review:'Reviews not found',
  no_chat_rooms:'No rooms created!',
  user_type:'Please select User Type',
  users_not_found:'Users not found',
  publicFeed_not_found:'Public feed not found',
  gender: 'Please select Gender.',

};