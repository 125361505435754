import React, { useEffect, useRef, useState, } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { ShowErrorToast, ShowToast } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { cmsPagesListApi } from '../../redux/actions/settingsActions';


const TermsModal = ({ modalVisible, setModalVisible, onCallBack, }) => {

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch()
    const authUser = useSelector(({ auth }) => auth.data);

    const [termsDetails, setTermsDetails] = useState({})

    useEffect(() => {
        dispatch(cmsPagesListApi(authUser.token))
            .then((res) => {
                if (res.status === 200) {
                    setTermsDetails(res.data.term)
                }
                else
                    ShowToast(res.message)
            }).catch((error) => {
                ShowErrorToast(error)
            })

    }, [])
    const content = termsDetails ? i18n.language === 'he' ? termsDetails.content_he : i18n.language === 'ar' ? termsDetails.content_ab : termsDetails.content : ""


    const handleClose = () => setModalVisible(!modalVisible);
    return (
        <>
            <Modal size='lg' show={modalVisible} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("navigate:termsncondition")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=' p-3'>
                        <div className='content mt-3 mb-3'
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsPrefix="site-btn"
                        style={{
                            // opacity: isReivewDone ? .6 : 1
                        }} variant="primary" onClick={() => {
                            onCallBack()
                            handleClose()
                        }}>
                        {t("common:accept")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TermsModal