import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { BASPATH, SHARE_BASPATH } from '../../../../config/appConfig'
import { attach, like, like_white, send_message, share } from '../../../../constants/images'
import { ShowLoginPupup, nFormatter } from '../../../../utils/common'
import { RWebShare } from "react-web-share";

import "./publicFeedList.css"
import { getDeepLinkUrl } from '../../../../constants/constant'
import { getDynamicUrlApi } from '../../../../redux/actions/settingsActions'
import { useSelector } from 'react-redux'
import HeadTags from '../../../../components/Layout/Seo'
import CommentSection from '../../../../components/Layout/listItemCommentSection'


const RendorImages = (item, index) => {
  //navigate(BASPATH + 'coupons')
  return (<li key={index}>
    <div onClick={() => { }} className="ms-1 me-1">
      {
        item.image.toString().endsWith("mp4") || item.image.toString().endsWith("mov") ?
          <video className='img-fluid' width="180" height="180" controls controlsList='nodownload ' >
            <source src={item.image} type="video/mp4" />
          </video>
          : <img className='img-fluid' height={180} width={180} src={item.image} alt="" />
      }
    </div>
  </li>)
}




const PublicFeedItem = ({ item, index, i18n, AddComment, onChangeValue, postComment, feedLike, onImageChange }) => {
  const { t } = useTranslation()
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate()

  const authUser = useSelector(({ auth }) => auth.data);

  const [photo, setPhoto] = useState(null);
  const [menuClick, setIsMenuClick] = useState(false);
  const hiddenFileInput = React.useRef(null);

  // useEffect(() => {
  //   if (item.profile_pic) {
  //     setPhoto(item.profile_pic);
  //   } else {
  //     setPhoto(null);
  //   }
  // }, []);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // const handleChange = event => {
  //     const fileUploaded = event.target.files[0];
  //     console.log('fileUploaded', fileUploaded)
  //     // props.handleFile(fileUploaded);
  //     onImageChange("profile_pic", fileUploaded)
  // };

  const handleChange = (item, index, event) => {
    console.log('item, index, event', item, index, event)
    const fileUploaded = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      setPhoto(URL.createObjectURL(event.target.files[0]));
    }
    // onImageChange("image", fileUploaded)
    onImageChange(item, AddComment[index], fileUploaded)
  };



  useEffect(() => {
    return () => {
    }
  }, [AddComment[index]])



  console.log('AddComment', AddComment)



  const public_feed_title = i18n.language === 'he' ? item.public_feed_title_he : i18n.language === 'ar' ? item.public_feed_title_ab : item.public_feed_title
  const content = i18n.language === 'he' ? item.content_he : i18n.language === 'ar' ? item.content_ab : item.content
  //const category_name = i18n.language === 'he' ? item.category_name_he : i18n.language === 'ar' ? item.category_name_ab : item.category_name
  const short_content = i18n.language === 'he' ? item.short_content_he : i18n.language === 'ar' ? item.cshort_content_ab : item.short_content


  const url = `${BASPATH}torah-collection-detail/${item.public_feed_id}`;
  const lastPath = `torah-collection-detail/${item.public_feed_id}` //

  //const shareUrl = `${SHARE_BASPATH}coupons-detail/${item.public_feed_id}`;
  const shareUrl = `${SHARE_BASPATH}torah-collection-detail/${item.public_feed_id}`;
 

  return <li key={index} className="col-md-12 ">
    <div className="public-feed-item">
      <h2 className='h4 pointer' onClick={() => {
        console.log('url', url)
        //     const url = `${BASPATH}torah-collection-detail/${item.public_feed_id}`;
        navigate(url, {
          state: {
            itemId: item.public_feed_id,
            item: item,
          }
        })
      }}>
        {public_feed_title}
      </h2>

      <p className='p mt-1'>
        {moment(item.updated_at).locale(i18n.language).format("YYYY-MM-DD hh:mm A")}
      </p>
      <p className='p mt-2 pointer' onClick={() => {
        navigate(url, {
          state: {
            itemId: item.public_feed_id,
            item: item,
          }
        })
      }}>
        {short_content}
      </p>

      <ul className="imageContainer">
        {item.images.map((item, index) => {
          return RendorImages(item, index)
        })
        }
      </ul>

      {item && <CommentSection item={item}
        index={index}
        handleChange={handleChange}
        onChangeValue={onChangeValue}
        AddComment={AddComment}
        postComment={postComment}
        onLike={feedLike}
        shareTitle={public_feed_title}
        isBlog={false} />}

      {/* <div className='public-feed-item-bottom'>
        <div className='search-wrapper mt-2'>
          <div className='input-attach-wrapper'>
            <input type='text' value={AddComment[index] ? AddComment[index] : ""} className='input-comment' onChange={(e) => onChangeValue(index, e)} placeholder={t("common:addComment")} />

            <img className='me-2 ms-2' onClick={handleClick} type='fluid' color='#29479F' src={attach} alt="share" height='20px' width='20px' />
            <input type="file"
              ref={hiddenFileInput}
              onChange={(e) => handleChange(item, index, e)}
              style={{ display: 'none' }}
            />
          </div>
          <div onClick={() => {
            if (!authUser.token) {
              ShowLoginPupup({ goToLogin: () => navigate(BASPATH + "auth/login"), title: t("navigate:login"), message: t("common:loginRequire"), ok: t("common:ok"), cancel: t("common:cancel") })
              return
            }
            if (AddComment.length > 0 && AddComment[index].trim() !== "") {
              postComment(item.public_feed_id, AddComment[index], undefined)

            }
          }} className='search-icon-wrapper pointer'>
            <img src={send_message} alt="Logo" width='24px' />
          </div>
        </div>
        <div className='like-wrapper pointer ms-2 me-2' onClick={() => {
          console.log('item', item.is_like)
          if (!authUser.token) {
            ShowLoginPupup({ goToLogin: () => navigate(BASPATH + "auth/login"), title: t("navigate:login"), message: t("common:loginRequire"), ok: t("common:ok"), cancel: t("common:cancel") })
            return
          }
          if (item.is_like !== 1) {
            feedLike(true, item.public_feed_id)
          } else if (item.is_like !== 0) {
            feedLike(false, item.public_feed_id)
          }
        }} >
          <img className='like_icon_margin' color='#29479F' src={item.is_like === 1 ? like : like_white} alt="share" height='24px' width='24px' />
          <span>
            {item.feed_like_count < 0 ? 0 : nFormatter(item.feed_like_count)}
          </span>
        </div>
        <div className='pointer ms-1 me-2'>
          <RWebShare
            data={{  
              url: shareUrl,
              title: public_feed_title,
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <img type='fluid' color='#29479F' src={share} alt="share" height='24px' width='24px' />
          </RWebShare>
 
        </div>
      </div> */}


    </div>
  </li>
}


export default PublicFeedItem