import React, { useEffect, useState, useRef } from "react";

import "./login.css";
import { BASPATH } from "../../../../config/appConfig";
import Header from "../../../../components/Layout/Header";

import { loginApi } from "../../../../redux/actions/loginActions";
import SignUp from "../../../../pages/SignUp";
import SignIn from "../../../../pages/SignIn";

const LoginComponent = props => {
  const [isSignUp, setSignUp] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <section>
      <Header setSignUp={setSignUp} />
      {!isSignUp ? (
        <SignIn setSignUp={setSignUp} />
      ) : (
        <SignUp setSignUp={setSignUp} />
      )}
    </section>
  );
};

export default LoginComponent;
