import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";


const BlogComponent = Loadable({
  loader: () => import("./components/blog"),
  loading: Loading
});
const BlogDetailComponent = Loadable({
  loader: () => import("./components/blog-detail/blogDetail"),
  loading: Loading
});

export const routes = [
  {
    path: BASPATH + "blog",
    exact: true,
    name: "blog",
    element: BlogComponent
  },
  {
    path: BASPATH + "blog-detail/:itemId",
    exact: true,
    name: "BlogDetail",
    element: BlogDetailComponent
  },
  {
    path: BASPATH + "blog-detail",
    exact: true,
    name: "BlogDetail",
    element: BlogDetailComponent
  },
   
  
];

// export * from "./components";
