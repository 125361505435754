export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    // console.log("navigator", navigator);
    navigator.serviceWorker
      .register("firebase-messaging-sw.js")
      .then(function(registration) {
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
        }
        // eslint-disable-next-line no-console
        console.log("[SW]: SCOPE: ", registration.scope);
        return registration.scope;
      })
      .catch(function(err) {
        return err;
      });
  }
};
