




import React from 'react'
import "./innerHeader.css"
import { useTranslation } from 'react-i18next';
import { search_ } from '../../../constants/images';
import { Button } from 'react-bootstrap';
import { isRTL } from '../../../utils/common';

const InnerHeader = ({ title = "", showSearch = false, searchQuery = "", handleInputChange, placeholder,
    showEditView =false, buttonText = "", isEditable, setEditable
 }) => {
    const { t, i18n } = useTranslation();
    return (
        <div id='inner-header' className='header-wrapper' dir={isRTL(i18n.language) ? "rtl" : ""} >
            <h2 className='h2'>
                {title}
            </h2>

            {
                showSearch && <div  className='search-wrapper' dir={isRTL(i18n.language) ? "rtl" : ""}>
                    <input value={searchQuery} onChange={handleInputChange} placeholder={placeholder} />
                    <div className='search-icon-wrapper'>
                        <img src={search_} alt="Logo" width='12px' />
                    </div>
                </div>
            }

            {
                showEditView && <Button size='lg' bsPrefix="back-btn ps-3 pe-3 pt-1 pb-1" className="pointer" variant="primary" onClick={() => {
                    setEditable(!isEditable)
                }}>  {buttonText}</Button>
            }
        </div>
    )
}

export default InnerHeader