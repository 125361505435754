import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

 
const NotificationComponent = Loadable({
  loader: () => import("./components/notification"),
  loading: Loading
});
 
export const routes = [
  {
    path: BASPATH + "notification",
    exact: true,
    name: "notification",
    element: NotificationComponent
  },
];

export * from "../auth/components";
