import React from "react";
import classes from './AuthCard.module.css';
import { Container } from 'react-bootstrap';

const AuthCard = props => {
  return (
    <section className={classes.auth}>
      <Container>
        <div className={classes.card}>
          {props.children}
        </div>
      </Container>
    </section>
  );
};

export default AuthCard;