import React, { useState } from "react";
import Navigation from "./Navigation";
import { Container, Row, Col } from 'react-bootstrap';

// Component CSS
import classes from './Header.module.css';
import { logo } from "../../constants/images";
import { useTranslation } from "react-i18next";
import { languages } from "../../constants/constant";
import { UseAppContext } from "../../contexts/AppProvider";

// Header Logo
//import logo from '../../assets/logo.png';


const Header = ({ setSignUp }) => {
  const { i18n } = useTranslation();

   const [lang, setLang] = useState('he');

  
  // document.body.dir = i18n.dir();
  // // This function put query that helps to 
  // // change the language
  const handleChange = e => {
    console.log('e.target.value', e.target.value)
    setLang(e.target.value);
    i18n.changeLanguage(e.target.value);
  }

  return (
    <>
      <header className={`${classes.header} pt-2 pb-2`}>
        <Container>
          <Row className="d-flex align-items-center">
            <Col>
              <div className="logo">
                <img src={logo} alt="Logo" width='80px' />
              </div>
            </Col>
            <Col>
              <div className="d-flex align-items-right justify-content-end">
                <div className="navigation">
                  <Navigation setSignUp={setSignUp} />
                </div>
                <select value={i18n.language} onChange={handleChange}>
                  {languages.map(item => {
                    return (<option key={item.value}
                      value={item.value}>{item.label}</option>);
                  })}
                </select>
              </div>
            </Col>
            {/* <Col>
             
            </Col> */}
          </Row>
        </Container>
      </header>
    </>
  );
};

export default Header;
