import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { TailSpin } from "react-loader-spinner";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoadingSpinner = props => (
  <div className={classNames("-loading", { "active -active": props.loading })}>
    <div id="spinner" style={{ top: "50%" }}>
      <i
        className="fa fa-spinner fa-pulse fa-3x fa-fw"
        style={{ fontSize: 36, color: "#ef6c00" }}
      />
      {props.loading ? (
        <TailSpin
          height="25"
          width={props.width ? props.width : "100"}
          color="grey"
          ariaLabel="loading"
        />
      ) : null}
    </div>
  </div>
);

LoadingSpinner.propTypes = {
  loading: PropTypes.bool
};

export default LoadingSpinner;
