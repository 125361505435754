import React from "react";
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

// Component CSS
import classes from './SignIn.css';
import './SignIn.css';

// Card Component
import AuthCard from "../components/UI/AuthCard";
import AuthCardLeft from "../components/UI/AuthCardLeft";
import AuthCardRight from "../components/UI/AuthCardRight";
import { useTranslation } from "react-i18next";
import SignUpForm from "../components/Auth/SignUpForm";

const SignUp = ({setSignUp}) => {
  const { t, i18n } = useTranslation()


  return (
    <>
      <AuthCard>
        <Row className="mb-5">
          <Col>
            <AuthCardRight className={classes.signincontent}>
              <SignUpForm />
            </AuthCardRight>
          </Col>
          <Col className="blue">
            <AuthCardLeft className={classes.signincontent}>
              <div className="signin-content-left" style={{padding : '80px 80px'}}>
                <h2 className={`signin-font-white h2 fw-bold`}>{t('common:alreadyHaveAccount')}</h2>
                {/* <p className={classes.signinp}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                <Button onClick={()=> setSignUp(false)}  variant="outline-primary" className='site-btn white-bg rounded-btn mt-3'>{t('common:signIn')}</Button>
              </div>
            </AuthCardLeft>
          </Col>
        </Row>
      </AuthCard>
    </>
  );
};

export default SignUp;