import React from 'react'
import "./commentSection.css"
import { ShowLoginPupup, nFormatter } from '../../../utils/common'
import { attach, like, like_white, send_message, share } from '../../../constants/images';
import { BASPATH, SHARE_BASPATH } from '../../../config/appConfig';
import { RWebShare } from 'react-web-share';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

const CommentSection = ({ item, index, handleChange, onChangeValue, AddComment, postComment, onLike, shareTitle, isBlog = true }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const authUser = useSelector(({ auth }) => auth.data);

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const ID = isBlog ? item.blog_id : item.public_feed_id

    const shareUrl = isBlog? `${SHARE_BASPATH}blog-detail/${ID}` : `${SHARE_BASPATH}torah-collection-detail/${ID}`;

    
    // const shareTitle= `${SHARE_BASPATH}blog-detail/${ID}`;
    const isList = typeof AddComment === 'string' ? false : true
    return (
        <div className='blog-item-bottom'>
            <div className='search-wrapper mt-2'>
                <div className='blog-input-attach-wrapper'>
                    <input type='text' value={isList ? AddComment[index] ? AddComment[index] : "" : AddComment} className='blog-input-comment'
                        onChange={(e) => isList ? onChangeValue(index, e) : onChangeValue(e)} placeholder={t("common:addComment")} />
                    <img className='me-2 ms-2' onClick={handleClick} type='fluid' color='#29479F' src={attach} alt="share" height='20px' width='20px' />
                    <input type="file"
                        ref={hiddenFileInput && hiddenFileInput}
                        onChange={(e) => isList ? handleChange(item, index, e) : handleChange(e)}
                        style={{ display: 'none' }}
                    />
                </div>
                <div onClick={() => {
                    if (!authUser.token) {
                        ShowLoginPupup({ goToLogin: () => navigate(BASPATH + "auth/login"), title: t("navigate:login"), message: t("common:loginRequire"), ok: t("common:ok"), cancel: t("common:cancel") })
                        return
                    }
                    if (!isList && AddComment.trim() !== "") {
                        postComment(undefined)
                    } else if (isList && AddComment.length > 0 && AddComment[index].trim() !== "") {
                        postComment(ID, AddComment[index], undefined)
                    }
                }} className='search-icon-wrapper pointer'>
                    <img src={send_message} alt="Logo" width='24px' />
                </div>
            </div>
            <div onClick={() => {
                console.log('item', item.is_like)
                if (item.is_like !== 1) {
                    if (!authUser.token) {
                        ShowLoginPupup({ goToLogin: () => navigate(BASPATH + "auth/login"), title: t("navigate:login"), message: t("common:loginRequire"), ok: t("common:ok"), cancel: t("common:cancel") })
                        return
                    }
                    onLike(true, ID)
                } else if (item.is_like !== 0) {
                    onLike(false, ID)
                }
            }} className='like-wrapper pointer ms-2 me-1'>
                <img className='like_icon_margin' color='#29479F' src={item.is_like === 1 ? like : like_white}
                    alt="share" height='24px' width='24px' />
                <span>
                    {isBlog? item.blog_like_count < 0 ? 0 : nFormatter(item.blog_like_count): 
                    item.feed_like_count < 0 ? 0 : nFormatter(item.feed_like_count) }
                </span>
            </div>
            <div className='pointer ms-1 me-1'>
                <RWebShare
                    data={{
                        url: shareUrl,
                        title: shareTitle,
                    }}
                    onClick={() => console.log("shared successfully!")}
                >
                    <img type='fluid' color='#29479F' src={share} alt="share" height='24px' width='24px' />
                </RWebShare>
            </div>
        </div>
    )
}

export default CommentSection