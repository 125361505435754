export default {

  signInWithGoogle: "Connectez-vous avec Google",
  signInWithApple: "Se connecter avec un foncé",
  email: "courriel",
  password: "mot",
  signIn: "retrait",
  register: "inscription",
  merchant: "récupération",
  user: "utilisateur",
  fname: "prénom",
  lname: "nom",
  businessName: "nom d'utilisateur",
  regNo: "P/S",
  website: "adresse du site",
  location_url: "adresse du site emplacement",
  emailAddress: "adresse email",


  createPassword: "Créer un mot de passe",
  confirmPassword: "validation du mot de passe",
  phoneNumber: "validation du mot de passe",
  idNumber: "Numéro Z",
  idNumbermsg: "Vous devrez ajouter un numéro de carte à puce pour vérifier le profil. Vous devrez vous rendre dans votre banque postale locale et/ou commander une carte en ligne avec livraison gratuite à domicile et fournir vos informations d'inscription, y compris la preuve de la pièce d'identité gouvernementale que vous avez saisie dans la demande au moment de l'inscription. Après avoir reçu la vérification, nous vérifierons votre profil.",
  streetAddName: "nom de rue",
  streetNo: "numéro de rue",
  houseNo: "Numéro de domicile",
  city: "ville",
  district: "locale",
  from: "Heure",
  to: "Heure :",
  addBusinessLogo: "ajouter un logo",
  businessLogo: "logo commercial",
  businessHours: "heures actives",


  validEmail: "please enter valid email.",
  validPassword: "please enter valid password.",

  signInAs: "S'inscrire comme :",
  donHaveAccount: "Non inscrit",
  alreadyHaveAccount: "Avez-vous déjà un compte ?",
  haventReceivedOTP: "Vous n'avez pas reçu de code ?",
  registerAs: "inscription à la file d'attente",
  verify: "identification",
  resend: "début",
  next: "suivant",
  back: "rétrograde",


  personalInformation: "renseignements personnels",
  morePersonalInfo: "Comptez sur vous-même",
  moreInfoBusiness: "Comptez sur votre entreprise",
  businessInformation: "informations commerciales",
  contactDetails: "contact",
  businessActivity: "activité commerciale",
  businessSector: "secteur d'activité",
  establishmentYear: "année d'établissement",

  maritalStatus: "état actuel",
  numbersofChildren: "Nombre d'enfants",
  occupation: "état",
  educationLevel: "éducation",

  VerifyPhoneNumber: "validation d'adresse e-mail",
  enterOtp: "Insérer le code de vérification",
  otpMsg: "Nous vous avons envoyé un mot de passe unique à votre adresse e-mail enregistrée.",


  languageSelector: 'Choisir une langue',

  changePassword: "changement de mot de passe",
  currentPassword: "mot de passe",
  newPassword: "nouveau mot de passe",
  chooseLanguage: "Choisir une langue",
  languageSettings: "langues",
  save: "enregistrer",
  cancel: "révocation",
  removeAccount: "Supprimer le compte",
  ok: "OK",
  yes: 'Oui',
  no: 'Non',
  permissions_for_record_audio: "Autorisations pour enregistrer de l'audio",
  give_permission_to_your_device_to_record_audio: "Autorisez votre appareil à enregistrer de l'audio",
  okay: "D'accord",


  coupons: "coupons",
  publicFeeds: "Licotti Torah",
  feeds: "profil personnel",
  blogs: "blog",
  search: "recherche",
  couponsMsg: "Affiche tous les coupons",
  blogsMsg: "blogs",

  couponTitle: "Flat 20% Discount at McDonald's",
  couponDesc: "Lorem Ipsum Terms & Conditions will go here",
  couponCode: "MCD20",
  validaty: "Billet ",
  couponExpDate: " 31 mai 2019",

  addBtn: "chef",
  updateBtn: "mise à jour",
  editBtn: "modifier",
  viewBtn: "afficher",
  saveBtn: "préserver",
  statsBtn: "statistiques",

  // feedsSearch:"",
  feedTitle: "Dream Creations Event",
  feedDesc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

  addComment: "réponse",

  blogTitle: "A Curious Fancy",
  blogDesc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  category: "catégorie",
  sports: "sports",
  report: "rapport",
  reportMessage: "Voulez-vous vraiment signaler ce contenu ?",
  readMore: "lire plus",
  readLess: "lire moins",
  recentComments: "commentaires récents",

  coupon_title: "Coupon Title",
  coupon_title_ab: "Coupon Title (Arabic)",
  coupon_title_he: "Coupon Title (Hebrew)",
  coupon_description: "Coupon Description",
  coupon_code: "code coupon",
  discount_amount: "Quantité remise",
  discount_type: "Type de remise",
  qrcode_url: "URL du code QR",
  storelocation: "Sélectionner un emplacement de magasin",
  storecategory: "Sélectionner une catégorie",
  create_date: "Créer une date",
  expiry_date: "date d'expiration",
  term_condition: "conditions",


  dabitcardTitle: "Vous avez déjà déposé votre demande en tant que \"résident intelligent d'Israël\". Veuillez vérifier l'état de votre demande ci-dessous.",
  dabitcardTitle1: "Cliquez sur Soumettre maintenant pour soumettre la demande ou ignorer pour continuer.",
  addTerms: "Ajouter des conditions générales",

  apply_filters: "Ajouter des filtres",
  apply: "mettre",
  clear_all: "Effacer tout",
  categories: "catégories",
  location: "espace",
  flat: "constante",
  percentage: "Monsieur",
  valid_all_stores: "S'applique à tous les magasins",
  my_coupons: "Mes coupons",

  thankyou: "merci",
  thankyou_msg: "Appuyez sur « Postuler maintenant » pour soumettre une candidature, ou sautez pour continuer.",
  applyAgain: "Appliquer à nouveau",
  cancelApplication: "annuler la demande",
  status: "stéthoscope",
  skip: "ignorer",
  applyNow: "appliquer maintenant",
  activeCoupons: "coupons actifs",
  inactiveCoupons: "coupons inactifs",
  couponsAddedBy: "coupons ajoutés par",
  couponsUsedBy: "coupons utilisés par",
  totalShare: "partage total",
  whatsapp: "Watsap",
  shareOnWhatsApp: "Partager dans Watson",
  shareOnEmail: "Partager dans l'e-mail",
  share: "attendre",
  accept: "merde",
  clearAll: "Effacer tout",
  code: "code",
  OneTimePassword: "Mot de passe unique",
  selectItem: "Sélectionner un élément",
  review: "examen",
  reviewTitle: "Quelle a été votre expérience",
  submit: "envoyer",
  chooseLibrary: "Sélectionner dans la bibliothèque",
  image: "instantané",
  video: "Wyodeo",
  loginwith: "Se connecter avec",
  or: "ou",
  document: "document",
  gallery: "galerie",
  audio: "est",
  Typemessage: "taper un message",

  media: "Inception",
  shareMedia: "Partager photos et vidéos",
  photos: "images",
  sharePhotos: "partager des photos",
  videos: "clips",
  shareVideos: "partager des vidéos",
  shareAudios: "partager audio",
  file: "Kabbas",
  shareFile: "partager des documents",
  selectFile: "Sélectionner un fichier",
  select: "sélectionner",
  block: "prêter",
  photo: "instantané",

  logout: "fermer",
  logout_message: "Voulez-vous vous déconnecter",
  delete_chat: "supprimer le message",
  delete_chat_message: "Voulez-vous supprimer un message ?",
  delete_account_name: "Supprimer le compte",
  delete_account_message: "Voulez-vous supprimer le compte ?",
  delete_post: "Supprimer le message",
  delete_post_message: "Voulez-vous supprimer le message ?",
  block_user: "Bloquer un utilisateur",
  block_user_message: "Voulez-vous bloquer un utilisateur ?",
  un_block_user: "Débloquer l'utilisateur",
  un_block_user_message: "Voulez-vous débloquer un utilisateur ?",
  disconnect_user: "Déconnecter l'utilisateur",
  disconnect_user_message: "Voulez-vous déconnecter l'utilisateur ?",
  connect_user: "Connecter l'utilisateur",
  connect_user_message: "Voulez-vous connecter l'utilisateur ?",

  message: "Message",
  edit_profile: "Editer le profil",
  posts: "Des postes",
  connects: "Se connecte",
  connect: "Connecter",
  connected: "Connecté",
  account_privacy: "Confidentialité du compte",
  public: "Publique",
  private: "Privé",
  private_account: "Compte privé",
  blocked_users: "Utilisateurs bloqués",
  privacy_msg1: "Lorsque votre compte est public, votre profil et vos publications peuvent être vus par n'importe qui, sur ou hors de Israel 18, même s'ils n'ont pas de compte Israel 18.",
  privacy_msg2: "Lorsque votre compte est privé, seules les connexions que vous approuvez peuvent voir ce que vous partagez, y compris vos photos ou vidéos, ainsi que vos listes de connexions.",
  go_back: "Retourner",
  create_post: "Créer un message",
  post_upload_msg: "Cliquez pour télécharger votre image et votre vidéo",
  post_error_msg: "Veuillez télécharger l'image ou la vidéo de votre message",
  comments: "commentaires",
  delete: "Supprimer",
  confirm: "Confirmer",
  under_maintenance: "L'application est actuellement en maintenance.",
  postCreateMsg: "écris une légende...",
  disconnect: "Déconnecter",
  unblock: "Débloquer",
  shabBatTital: "Créer un profil en tant qu'observateur du Shabbat",
  shabBatDesc: "J'aimerais être exposé à des annonceurs qui observent le Shabbat et/ou à ceux qui définissent leur compte comme observateur du Shabbat et pénitent.",
  on: "actif",
  off: "éteindre",
  bio: "Biographie",
  user_name: "Nom d'utilisateur",
  link: "Lien",
  views: "vues",
  view: "voir",
  shabBat: "Sabbat",
  accountIsPrivate: "Ce compte est privé",
  locationNotFound: 'Emplacement introuvable',
  requested:'Demandée',
  seemore:'Voir plus',
  broadcastEnd:'La diffusion a été interrompue',
  cancelRequsted:'Annulation demandée',
  cancelRequsted_message:"Voulez-vous annuler la demande?",
  gender:'Genre',
  yourlocation:  "Sélectionnez votre ville",
  profileComplete:'Profil complet',
  profileComplete_msg:'Veuillez compléter votre profil.',
  isLive:"est en direct!",
  goToLive:"Aller en direct",
  leaveRoom:"Quitter la pièce",
  live:"En direct",

  filters: "Filters",
  allConversion: "All Conversations",
  unread:"Unread",
  read:"Read",
  favorites:"Favorites",
  archive:"Archive",
  trash:"Trash",



  //web app text
  popularCategories: "Catégories populaires",
  latestCoupon: "Derniers coupons",
  latestBlogs: "Derniers blogs",
  recentPost: "Messages récents",
  copyCode: "Copier le code",
  couponMsg: "Utilisez ce code promo à la caisse",
  loadMore: "Charger plus",
  seeAllBlogs: "Voir tous les blogs",
  upto: "JUSQU'À",
  off: "DÉSACTIVÉ",
  downloadApp: "Téléchargez notre application",
  allRightReserved: "תושב חכם Israel 18 Tous droits réservés @2023",
  loginRequire: "Vous devez être connecté",
  type:"Taper",
  selectAudience:"Sélectionnez le public",
  start: "Commencer",
};