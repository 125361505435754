export const IS_DEBUG = true;
export const IS_CLIENT_SERVER = true;

export const BASPATH = IS_DEBUG
  ? "/"
  : IS_CLIENT_SERVER
  ? "/"
  : "/smartcitizen/"; //   "/"; // /smartcitizen/   // toshavhaham/
//export const SHARE_BASPATH = "http://localhost:3000/smartcitizen/"; // /smartcitizen/
//export const SHARE_BASPATH = "http://15.207.152.121/smartcitizen/"; // /smartcitizen/
//export const SHARE_BASPATH = IS_CLIENT_SERVER ? "http://78.47.88.161/toshavhaham/" : "http://15.207.152.121/smartcitizen/"; // /smartcitizen/
export const SHARE_BASPATH = IS_CLIENT_SERVER
  ? "https://toshavhaham.co.il/"
  : "https://knp-tech.in/smartcitizen/"; // /smartcitizen/

export const DYNAMIC_LINK_API =
  "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDHzXF9_hJgOJIQlj_DiJfQ-w9z9hyI3vI";

// export const GOOGLE_LOGIN_CLIENT_ID = IS_CLIENT_SERVER
//     ? "874633988498-kflm6gghtn4p74620vevdmafr45ssasv.apps.googleusercontent.com"
//     : "35482678342-1tfr0ja7tgpppv7a77qmenpida9cf600.apps.googleusercontent.com"
export const GOOGLE_LOGIN_CLIENT_ID =
  "874633988498-kflm6gghtn4p74620vevdmafr45ssasv.apps.googleusercontent.com";
export const GOOGLE_VAPID_KEY =
  "BGjBFuvQygxj8JBYnR-jKcdvCSCtShWCZ3LrhSO1miMevsdJLv-9aDPL06dIWtHut8X1nZ1RV_SqixkzsTB2XlE";
