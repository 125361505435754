import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { validateEmail } from '../../utils/common'
import CustomInput from '../UI/CustomInput'
import TermsModal from '../UI/termsModel'
import SocialLogin from './SocialLogin'



const UserForm = ({ userState, handleInputChangeUser, handleRegister }) => {
    const { t, i18n } = useTranslation()

    const [errorState, setErrors] = useState({});

    const [agree, setAgree] = useState(false);
    const [showTermspopup, setShowTermspopup] = useState(false);

    const checkboxHandler = () => {
        setShowTermspopup(!showTermspopup)
    }
    const onCallBack = () => {
        console.log('onCallBack', )
        setAgree(true);
    }


    useEffect(() => {
        setErrors({})
    }, [i18n.language])


    const validateForm = () => {
        let fields = userState;
        let errors = {};
        let formIsValid = true;

        console.log('loginState', fields)

        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = t("error:first_name");
        }
        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = t("error:last_name");
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = t("error:email");
        }

        if (!validateEmail(fields["email"])) {
            formIsValid = false;
            errors["email"] = t("error:email");
          }
        if (!fields["password"] || fields["password"].length < 6) {
            formIsValid = false;
            errors["password"] = t("error:password");
        }
        if (!fields["password_confirmation"].trim() || fields["password"] !== fields["password_confirmation"]) {
            formIsValid = false;
            errors["password_confirmation"] = t("error:password_confirmation");
          }
        setErrors(errors);
        return formIsValid;
    };

    const onSubmit = (e) => {
        if (validateForm()) {
            handleRegister(e)
        }

    }

    console.log('errorState', errorState)

    return (
        <>
            <CustomInput name={'first_name'}
                value={userState.first_name}
                placeholder={t('common:fname')}
                onChange={handleInputChangeUser}
                errormsg={errorState["first_name"]}
                type={'text'} />
            <CustomInput name={'last_name'}
                value={userState.last_name}
                placeholder={t('common:lname')}
                onChange={handleInputChangeUser}
                errormsg={errorState["last_name"]}
                type={'text'} />
            <CustomInput name={'email'}
                value={userState.email}
                placeholder={t('common:emailAddress')}
                onChange={handleInputChangeUser}
                errormsg={errorState["email"]}
                type={'email'} />
            <CustomInput name={'password'}
                value={userState.password}
                placeholder={t('common:createPassword')}
                onChange={handleInputChangeUser}
                errormsg={errorState["password"]}
                type={'password'} />
            <CustomInput name={'password_confirmation'}
                value={userState.password_confirmation}
                placeholder={t('common:confirmPassword')}
                onChange={handleInputChangeUser}
                errormsg={errorState["password_confirmation"]}
                type={'password'} />
            <CustomInput name={'phone_number'}
                value={userState.phone_number}
                placeholder={t('common:phoneNumber') + " (" + t("error:optional") + ")"}
                onChange={handleInputChangeUser}
                errormsg={errorState["phone_number"]}
                type={'number'} />
             
             <div className='d-flex p-2 align-center' >
                <input checked={agree} type="checkbox" id="agree" />
                <label onClick={() => { checkboxHandler() }} className='ms-2 me-2 pointer' htmlFor="agree"> <b>{" " + t('navigate:termsncondition')}</b></label>
            </div>

            <TermsModal
                modalVisible={showTermspopup}
                setModalVisible={setShowTermspopup}
                onCallBack={onCallBack}
            />

            {/* <Row>
                <Col>
                    <SocialLogin />
                </Col>
            </Row> */}

            <Row className='mt-2'>
                <Col>
                    <Button disabled={!agree} style={{
                        opacity: !agree ? .6 : 1
                    }} bsPrefix="site-btn rounded-btn" onClick={onSubmit} className="" variant="primary">{t('navigate:register')}</Button>
                </Col>
            </Row>
        </>
    )
}

export default UserForm