import { COLORS } from "../theme";
//import dynamicLinks from '@react-native-firebase/dynamic-links';

export const APP_NAME = "תושב חכם ISRAEL 18"

const maritalData = [
  { label: 'Single', label_ar: 'غير مرتبطة', label_he: 'יחיד', value: 'Single' },
  { label: 'Married', label_ar: 'متزوج', label_he: 'נָשׂוּי', value: 'Married' },
  { label: 'Widowed', label_ar: 'الأرامل', label_he: 'אלמנה', value: 'Widowed' },
  { label: 'Separated', label_ar: 'منفصل', label_he: 'מופרד', value: 'Separated' },
  { label: 'Divorced', label_ar: 'مُطلّق', label_he: 'גרושה', value: 'Divorced' },
];
const GenderData = [
  { label: 'Male', label_ar: 'ذكر', label_he: 'זָכָר', label_fr: 'Mâle', label_ru: 'Мужской', value: 'Male' },
  { label: 'Female', label_ar: 'أنثى', label_he: 'נְקֵבָה', label_fr: 'Femelle', label_ru: 'Женский', value: 'Female' },
  { label: 'Other', label_ar: 'آخر', label_he: 'אַחֵר', label_fr: 'Autre', label_ru: 'Другой', value: 'Other' },
];
const UserTypeData = [
  { label: 'User', label_ar: 'ذكر', label_he: 'זָכָר', label_fr: 'Mâle', label_ru: 'Мужской', value: 0 },
  { label: 'Merchant', label_ar: 'أنثى', label_he: 'נְקֵבָה', label_fr: 'Femelle', label_ru: 'Женский', value: 1 },
];
const ShabbatTypeData = [
  { label: 'ON', label_ar: 'ذكر', label_he: 'זָכָר', label_fr: 'Mâle', label_ru: 'Мужской', value: 1 },
  { label: 'OFF', label_ar: 'أنثى', label_he: 'נְקֵבָה', label_fr: 'Femelle', label_ru: 'Женский', value: 0 },
];
const ChildrenData = [
  { label: ' 0', label_ar: ' 0', label_he: ' 0', value: '0' },
  { label: ' 1', label_ar: ' 1', label_he: ' 1', value: '1' },
  { label: ' 2', label_ar: ' 2', label_he: ' 2', value: '2' },
  { label: ' 3', label_ar: ' 3', label_he: ' 3', value: '3' },
  { label: ' 4', label_ar: ' 4', label_he: ' 4', value: '4' },
  { label: ' 5', label_ar: ' 5', label_he: ' 5', value: '5' },
];
const EducationData = [
  { label: 'Primary Education', label_ar: 'تعليم ابتدائي', label_he: 'חינוך יסודי', value: 'Primary', },
  { label: 'Secondary Education', label_ar: 'التعليم الثانوي', label_he: 'חינוך תיכוני', value: 'Secondary', },
  { label: 'Bachelor\'s Degree', label_ar: 'درجة البكالريوس', label_he: 'תואר ראשון', value: 'Bachelor', },
  { label: 'Master\'s Degree', label_ar: 'ماجيستير', label_he: 'תואר שני', value: 'Master', },
  { label: 'Doctorate or higher', label_ar: 'درجة الدكتوراه أو أعلى', label_he: 'דוקטורט ומעלה', value: 'Doctorate', },
];


const AMPM = [
  { label: 'AM', label_ar: 'AM', label_he: 'AM', value: 'AM' },
  { label: 'PM', label_ar: 'PM', label_he: 'PM', value: 'PM' },
];
const businessActivity = [
  { label: 'Construction', label_ar: 'بناء', label_he: 'בְּנִיָה', value: 'Construction' },
  { label: 'Education', label_ar: 'تعليم', label_he: 'חינוך', value: 'Education' },
  { label: 'Financial and insurance Service', label_ar: 'خدمة المالية والتأمين', label_he: 'שירות פיננסי וביטוח', value: 'FinancialInsurance' },
  { label: 'Accommodation and Food', label_ar: 'الإقامة والطعام', label_he: 'לינה ואוכל', value: 'Accommodation' },
  { label: 'Hospital and Medical Care', label_ar: 'المستشفى والرعاية الطبية', label_he: 'בית חולים וטיפול רפואי', value: 'MedicalCare' },
  { label: 'Trade', label_ar: 'تجارة', label_he: 'סַחַר', value: 'Trade' },
];
const businessSector = [
  { label: 'Construction', label_ar: 'بناء', label_he: 'בְּנִיָה', value: 'Construction' },
  { label: 'Education', label_ar: 'تعليم', label_he: 'חינוך', value: 'Education' },
  { label: 'Chemical industries', label_ar: 'الصناعات الكيماوية', label_he: 'תעשיות כימיות', value: 'Chemical' },
  { label: 'Commerce', label_ar: 'تجارة', label_he: 'מִסְחָר', value: 'Commerce' },
  { label: 'Health Service', label_ar: 'خدمة صحية', label_he: 'שירות בריאות', value: 'Health' },
];

const languages = [
  { value: 'en', label: 'English', label_ar: 'English', label_he: 'English', label_fr: 'English', label_ru: 'English' },
  { value: 'ar', label: 'Arabic', label_ar: 'Arabic', label_he: 'Arabic', label_fr: 'Arabic', label_ru: 'Arabic' },
  { value: 'he', label: 'Hebrew', label_ar: 'Hebrew', label_he: 'Hebrew', label_fr: 'Hebrew', label_ru: 'Hebrew' },
  // { value: 'ru', label: 'Russian', label_ar: 'Russian', label_he: 'Russian', label_fr: 'Russian', label_ru: 'Russian' },
  // { value: 'fr', label: 'French', label_ar: 'French', label_he: 'French', label_fr: 'French', label_ru: 'French' },
];


const fontMargin = 1


export const REPORT_DATA_en = [
  "I just don't like it",
  "It's spam",
  "Nudity or sexual activity",
  "Hate speech or symbols",
  "Violence or dangerous organisations",
  "Bullying or harassment",
  "False information",
  "Scam or fraud",
  "Suicide or self-injury",
  "Sale of illegal or regulated goods",
  "Intellectual property violation",
  "Eating disorders",
  "Something else",
]

export const REPORT_DATA_he = [
  "אני פשוט לא אוהב את זה",
  "זה ספאם",
  "עירום או פעילות מינית",
  "דברי שנאה או סמלים",
  "אלימות או ארגונים מסוכנים",
  "בריונות או הטרדה",
  "מידע שגוי",
  "הונאה או הונאה",
  "התאבדות או פציעה עצמית",
  "מכירה של סחורות לא חוקיות או בפיקוח",
  "הפרת קניין רוחני",
  "הפרעות אכילה",
  "משהו אחר"
]
export const REPORT_DATA_ar = [
  "أنا فقط لا أحب ذلك",
  "أنها رسائل مزعجه",
  "العري أو النشاط الجنسي",
  "خطاب الكراهية أو الرموز",
  "العنف أو المنظمات الخطرة",
  "التنمر أو التحرش",
  "معلومات خاطئة",
  "احتيال أو احتيال",
  "الانتحار أو إيذاء النفس",
  "بيع البضائع غير القانونية أو المنظمة",
  "انتهاك الملكية الفكرية",
  "اضطرابات الاكل",
  "شيء آخر"
]
export const REPORT_DATA_fr = [
  "Je n'aime tout simplement pas ça",
  "C'est un spam",
  "Nudité ou activité sexuelle",
  "Discours ou symboles de haine",
  "Violence ou organisations dangereuses",
  "Intimidation ou harcèlement",
  "Fausse information",
  "Arnaque ou fraude",
  "Suicide ou automutilation",
  "Vente de marchandises illégales ou réglementées",
  "Violation de la propriété intellectuelle",
  "Troubles de l'alimentation",
  "Autre chose"
]
export const REPORT_DATA_ru = [
  "мне это просто не нравится",
  "Это спам",
  "Нагота или сексуальная активность",
  "Разжигание ненависти или символы",
  "Насилие или опасные организации",
  "Запугивание или преследование",
  "Ложная информация",
  "Мошенничество или мошенничество",
  "Самоубийство или членовредительство",
  "Продажа нелегальных или подконтрольных товаров",
  "Нарушение интеллектуальной собственности",
  "Расстройства пищевого поведения",
  "Что-то другое"
]




const createFileUriFromContentUri = async (contentUri) => {
  // const fileUri = contentUri
  //   .replace(
  //     'com.android.providers.media.documents/document/video%3A',
  //     'media/external/video/media/',
  //   );
  // const uriComponents = fileUri.split('/');
  // const fileName = uriComponents[uriComponents.length - 1];
  // const newFilePath = `${RNFS.TemporaryDirectoryPath}/${fileName}`;
  // await copyFile(contentUri, newFilePath);

  // return `file://${newFilePath}`;
}

const getDeepLinkUrl = async (url) => {
  // const link = await dynamicLinks().buildShortLink({
  //   link: url,
  //   domainUriPrefix: 'https://toshavhahamapp.page.link',
  //   android: {
  //     packageName: 'com.toshavhaham',
  //   },
  //   ios: {
  //     bundleId: 'com.smartcitizen',
  //     appStoreId: '1658696488',
  //   },
  //   navigation: {
  //     forcedRedirectEnabled: true
  //   },

  // }, dynamicLinks.ShortLinkType.UNGUESSABLE);

  // //console.log('link', link)

  // return link
}



const onShare = async (title, lastPath) => {
  //   const url = `https://toshavhaham/${lastPath}`
  //   const link = await getDeepLinkUrl(url)
  //   console.log('getDeepLinkUrl link', link)

  //   const options = {
  //     title: title,
  //     message: title + " \n" + link,
  //     activityItemSources: [
  //         {
  //            placeholderItem: {
  //                 type: 'url',
  //                 content: `${title} ${link}`
  //             },
  //             item: {
  //                 default: {
  //                     type: 'text',
  //                     content: `${title} ${link}`
  //                 },
  //             },
  //             linkMetadata: {
  //                 title: title,
  //                 icon: link
  //             }
  //         }
  //     ],
  // }
  //   try {
  //     const result = await Share.share(options);
  //     if (result.action === Share.sharedAction) {
  //       if (result.activityType) {
  //         // shared with activity type of result.activityType
  //       } else {
  //         // shared
  //       }
  //     } else if (result.action === Share.dismissedAction) {
  //       // dismissed
  //     }
  //   } catch (error) {
  //     Alert.alert(error.message);
  //   }
};

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};



export {
  maritalData, ChildrenData,
  EducationData, AMPM, businessActivity,
  businessSector,
  languages, GenderData, ShabbatTypeData, UserTypeData,
  createFileUriFromContentUri,
  onShare,
  getDeepLinkUrl,
  openInNewTab
};