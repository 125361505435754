import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

const DashboardComponent = Loadable({
  loader: () => import("./components/dashboard/dashboard"),
  loading: Loading
});

const LandingComponent = Loadable({
  loader: () => import("../auth/components/invite/landing"),
  loading: Loading
});
export const routes = [
  {
    path: BASPATH,
    exact: true,
    name: "dashboard",
    element: DashboardComponent
  },
  {
    path: BASPATH + "landing",
    exact: true,
    name: "Landing",
    element: LandingComponent
  },
  // {
  //   path: BASPATH + "dashboard",
  //   exact: true,
  //   name: "dashboard",
  //   element: DashboardComponent
  // },
];

export * from "./components";
