import axios from "axios";
import { actions } from "./index";
import config from "../../config";


const CHAT_GROUP_SUCCESS = "CHAT_GROUP_SUCCESS";
const CHAT_GROUP_FAILED = "CHAT_GROUP_FAILED";


export const settingsActions = {
  CHAT_GROUP_SUCCESS,
  CHAT_GROUP_FAILED,
};


// chat_group_list

export const chatGroupApi = (request, token) => dispatch => {
  // console.log("changePasswordApi request", request)
  return new Promise((resolve, reject) => {
    const onSuccess = response => {
      const data = response.data
      // console.log("chatGroupApi response", data)
      //if (data.success) {
      resolve(data)
      // }
    }
    const onFailure = (error) => {
      console.log("chat_group_list error:: ", error)
      reject(error.response)
    }

    axios.post(config.api.baseURL + "chat_group_list", request, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(onSuccess)
      .catch(onFailure)

  })
}
export const chatSingleMessageApi = (request, token) => dispatch => {
  //console.log("chatSingleMessageApi request", request)
  return new Promise((resolve, reject) => {
    const onSuccess = response => {
      const data = response.data
      //if (data.success) {
      resolve(data)
      // }
    }
    const onFailure = (error) => {
      console.log("chat_single_message error:: ", error.response.data)
      reject(error.response)
    }

    axios.post(config.api.baseURL + "chat_single_message", request, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(onSuccess)
      .catch(onFailure)

  })
}
export const updateUnreadStatus = (request, token) => dispatch => {
  // console.log("changePasswordApi request", request)
  return new Promise((resolve, reject) => {
    const onSuccess = response => {
      const data = response.data
      //if (data.success) {
      resolve(data)
      // }
    }
    const onFailure = (error) => {
      console.log("update_unread_status error:: ", error.response.data)
      reject(error.response)
    }

    axios.post(config.api.baseURL + "update_unread_status", request, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(onSuccess)
      .catch(onFailure)

  })
}

export const uploadMedia = (request, token) => dispatch => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      const data = response.data
      // if (data.success) {
      resolve(data)
      //  }
    }
    const onFailure = (error) => {
      reject(error.response)
    }
    axios.post(config.api.baseURL + "chat_media", request, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      }
    }).then(onSuccess)
      .catch(onFailure)

  })
}

export const searchChatUserApi = (request, token) => dispatch => {
  return new Promise((resolve, reject) => {
    const onSuccess = response => {
      const data = response.data
      resolve(data)
    }
    const onFailure = (error) => {
      console.log("chat_group_list error:: ", error)
      reject(error.response)
    }

    axios.post(config.api.baseURL + "user_list", request, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(onSuccess)
      .catch(onFailure)

  })
}
export const chatGroupChangeStatusApi = (request, token) => dispatch => {
  return new Promise((resolve, reject) => {
    const onSuccess = response => {
      const data = response.data
      resolve(data)
    }
    const onFailure = (error) => {
      console.log("chat_group_list error:: ", error)
      reject(error.response)
    }

    axios.post(config.api.baseURL + "chat_group_change_status", request, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(onSuccess)
      .catch(onFailure)

  })
}

export const postLiveStream0 = (request, token) => dispatch => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {
      const data = response.data
      // console.log('livestream_recording response data', data)
      resolve(data)
      if (data.success) {
      }
    }
    const onFailure = (error) => {
      reject(error.response)
    }
    axios.post(config.api.baseURL + "livestream_recording", request, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      },
      responseType: 'blob',
    }).then(onSuccess)
      .catch(onFailure)
  })
}

export const postLiveStream = (request, token) => dispatch => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response) => {

      const contentType = response.headers['content-type'];
      if (contentType.includes('application/json')) {
        // If the content type is JSON, parse the blob to JSON
        response.data.text().then((text) => {
          const json = JSON.parse(text);
          console.log('livestream_recording JSON', json); // Handle the JSON response (like error messages)
          resolve(json)
        });
      } else {
        // Handle the blob (binary data)
        console.log('Received a Blob:', response.data);
        // You can trigger a download or further processing
        const data = response
        console.log('livestream_recording response data', data)
        resolve(data)
      }



      // if (data.success) {
      // }
    }
    const onFailure = (error) => {
      reject(error.response)
    }

    axios.post(config.api.baseURL + "livestream_recording_with_plan", request, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      },
      responseType: 'blob',
    }).then(onSuccess)
      .catch(onFailure)
  })
  //   axios.post(config.api.baseURL + "livestream_recording", request, {
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'content-type': 'multipart/form-data',
  //     },
  //     responseType: 'blob',
  //   }).then(onSuccess)
  //     .catch(onFailure)
  // })
}

export const downloadSampleCsvFile = (url) => {
  // throw new Error("uncomment this line to mock failure of API");
  return axios.get(
    url,
    {
      responseType: "blob",
    }
  );
};