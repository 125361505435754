import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

const Social = Loadable({
  loader: () => import("./components/social"),
  loading: Loading
});
const PostList = Loadable({
  loader: () => import("./components/postList"),
  loading: Loading
});
const PostDetail = Loadable({
  loader: () => import("./components/postDetail"),
  loading: Loading
});
const SocialUserProfile = Loadable({
  loader: () => import("./components/SocialUserProfile"),
  loading: Loading
});
const SocialSetting = Loadable({
  loader: () => import("./components/Settings"),
  loading: Loading
});

export const routesSocialChild = [
  //   {
  //     path: BASPATH + "social",
  //     exact: true,
  //     name: "social",
  //     element: Social
  //   },
  {
    path: "*",
    name: "posts",
    element: PostList
  },
  {
    path: "posts",
    name: "posts",
    element: PostList
  },

  {
    path: "public-feed-detail/:itemId",
    name: "postDetails",
    element: PostDetail
  },
  {
    path: "social-profile",
    name: "socialProfile",
    element: SocialUserProfile
  },
  {
    path: "social-profile/:itemId",
    name: "socialProfileDetails",
    element: SocialUserProfile
  },
  {
    path: "account-privacy",
    name: "account_privacy",
    element: SocialSetting
  }
];
export const routes = [
  {
    path: BASPATH + "social/*",
    exact: true,
    name: "social",
    element: Social
  }
];

export * from "../auth/components";
