export default {
  first_name: 'الرجاء إدخال الاسم الأول.',
  last_name: 'الرجاء إدخال الاسم الأخير.',
  email: 'الرجاء إدخال عنوان بريد إلكتروني صالح.',
  password: 'الرجاء إدخال كلمة مرور صالحة.',
  current_Password: 'الرجاء إدخال كلمة مرور صالحة.',
  new_Password: 'الرجاء إدخال كلمة مرور جديدة صالحة.',
  password_confirmation_new: 'كلمة المرور الحالية وكلمة المرور الجديدة غير متطابقتين.',
  password_confirmation: 'كلمة المرور وتأكيد كلمة المرور غير متطابقين.',
  phone_number: 'الرجاء إدخال رقم هاتف صحيح.',
  id_number: 'الرجاء إدخال رقم الهوية.',
  marital_status: 'الرجاء تحديد الحالة الاجتماعية.',
  no_of_child: 'الرجاء تحديد عدد الأطفال.',
  occupation: 'الرجاء إدخال المهنة.',
  education_level: 'الرجاء تحديد مستوى التعليم.',
  house_number: 'الرجاء إدخال رقم المنزل',
  city: 'الرجاء إدخال المدينة.',
  coupon_title: 'الرجاء إدخال عنوان القسيمة.',
  business_name: 'الرجاء إدخال الاسم التجاري.',
  registration_number: 'الرجاء إدخال رقم التسجيل.',
  website:'الرجاء إدخال الموقع.',
  location_url:'الرجاء إدخال عنوان url الموقع.',
  business_activity:'يرجى تحديد نشاط الأعمال.',
  businessSector:'الرجاء تحديد قطاع الأعمال.',
  establishment_year:'الرجاء إدخال سنة التأسيس الصحيحة.',
  business_hours:'الرجاء إدخال ساعات العمل.',
  street_address_name:'الرجاء إدخال اسم عنوان الشارع.',
  street_number:'الرجاء إدخال رقم الشارع.',
  district:'الرجاء إدخال منطقتك.',
  code:'الرجاء إدخال الرمز الخاص بك.',
  oneTimePassword:'الرجاء إدخال كلمة المرور لمرة واحدة (OTP).',
  terms:'الرجاء إضافة الشروط والأحكام',

  coupon_description: "الرجاء إدخال وصف القسيمة",
  coupon_code: "الرجاء إدخال رمز القسيمة",
  qrcode_url: "الرجاء إدخال عنوان URL لرمز الاستجابة السريعة",
  discount_amount: "الرجاء إدخال مبلغ الخصم",
  discount_type: "الرجاء تحديد نوع الخصم",
  discount_percent: "يجب أن يكون الحد الأقصى للخصم 100٪.",
  location_id: "الرجاء تحديد الموقع",
  category_id: "الرجاء تحديد الفئة",
  delete_coupon: "هل أنت متأكد أنك تريد حذف هذه القسيمة؟",

  blogs_not_found:'المدونات غير موجودة',
  coupons_not_found:'القسائم غير موجودة',
  feed_not_found:'لم يتم العثور على مجموعة التوراة',
  notification_not_found:'لم يتم العثور على الإخطارات',
  recent_comment_not_found:'لم يتم العثور على التعليق الأخير',
  optional:'اختياري',
  review_not_found:'لم يتم العثور على مراجعات',
  review:'التعليقات غير موجودة',
  no_chat_rooms:'لم يتم إنشاء غرف!',
  user_type:'الرجاء تحديد نوع المستخدم',
  users_not_found:'لم يتم العثور على المستخدمين',
  publicFeed_not_found:'لم يتم العثور على الخلاصة العامة',
  gender_error: 'يرجى تحديد الجنس.',
};