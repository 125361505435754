import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { onMessageListener, fetchToken, requestForToken } from "./firebase";
import { BASPATH } from "../config/appConfig";
import { useNavigate } from "react-router";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [payload, setPayload] = useState();
  const navigate = useNavigate();

  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div
        className="pointer"
        onClick={() => {
          console.log("payload", payload.data);
          const item = payload.data;
          const url = `${BASPATH}live-join-viewer/${item.roomId}`;
          console.log("url", url);
          navigate(url, {
            state: {
              itemId: item.roomId,
              item: item
            }
          });
        }}
      >
        <p>
          <b>{notification.title}</b>
        </p>
        <p>{notification.body}</p>
      </div>
    );
  }

  useEffect(() => {
    console.log("notification", notification);
    if (notification) {
      if (notification.title) {
        notify();
      }
    }
  }, [notification]);

  requestForToken();
  // requestForToken();

  onMessageListener()
    .then(payload => {
      console.log("payload", payload.data);
      if (payload && payload.notification) {
        setPayload(payload);
        if (payload.data.type == "broadcasting")
          setNotification({
            title: payload.notification.title,
            body: payload.notification.body
          });
      }
    })
    .catch(err => console.log("failed: ", err));

  return <Toaster />;
};

export default Notification;
